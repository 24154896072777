import {
  differenceInSeconds,
  format,
  formatDistance,
  isSameMonth,
  isSameSecond,
  isValid,
  parse,
  parseISO,
  parseJSON,
} from "date-fns";
import { de as locale } from "date-fns/locale";

type DateType = Date | string;

export const parsedDate = (date: DateType, local = false) => {
  if (typeof date === "string") {
    if (local) return parseDateLocal(date);
    // local ? parseISO(date) : parseJSON(date)
    return parseDateUtc(date);
  }

  return date;
};

export const parseDateLocal = (date: string) => {
  let rslt = parseISO(date);
  if (isValid(rslt)) {
    return rslt;
  }

  rslt = parse(date, "yyyy-MM-dd", new Date());
  if (isValid(rslt)) {
    return rslt;
  }

  throw new Error(`Unknown date format: ${date}`);
};

export const parseDateUtc = (date: string) => {
  let rslt = parseJSON(date);
  if (isValid(rslt)) {
    return rslt;
  }

  rslt = parse(date, "yyyy-MM-dd", new Date());
  if (isValid(rslt)) {
    return rslt;
  }

  throw new Error(`Unknown date format: ${date}`);
};

export const dateFormat = (date: DateType, formatStr = "d.M.yyyy", local = false) =>
  format(parsedDate(date, local), formatStr, { locale });
export const detailedDateFormat = (date: DateType) => dateFormat(date, "EEEE, d. LLLL yyyy");
export const dateTimeFormat = (date: DateType, formatStr = "d.M.yyyy H:mm", local = false) =>
  dateFormat(date, formatStr, local);
export const detailedDateTimeFormat = (date: DateType) => dateFormat(date, "EEEE, d. LLLL yyyy, H:mm 'Uhr'");

export const relativeTime = (date: DateType, fromDate = new Date()) =>
  formatDistance(parsedDate(date), fromDate, { locale, addSuffix: true });

export const nearlySimultaneously = (date1: DateType, date2: DateType) =>
  isSameSecond(parsedDate(date1), parsedDate(date2));

export const timeFormat = (date: DateType, formatStr = "H:mm") => dateFormat(date, formatStr);

export const dateRangeFormatDays = (startDate: Date, endDate: Date) => {
  const diff = differenceInSeconds(endDate, startDate);

  if (diff === 0) {
    return dateFormat(startDate);
  }

  if (diff < 86399) {
    return `${timeFormat(startDate)} - ${dateTimeFormat(endDate, "H:mm d.M.yyyy")}`;
  }

  if (diff >= 86399 && diff <= 86400) {
    return dateFormat(startDate);
  }

  if (isSameMonth(startDate, endDate)) {
    return `${dateFormat(startDate, "d.")} - ${dateFormat(endDate)}`;
  }

  return `${dateFormat(startDate)} - ${dateFormat(endDate)}`;
};
