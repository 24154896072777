import "./info-modal-style.scss";

import { useQuery } from "@apollo/client";

import { parseISO } from "date-fns";
import Modal from "react-bootstrap/Modal";

import DoneButton from "../../containers/buttons/DoneButton";
import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { MainGroupType } from "../admin/main_groups/types";
import { NumberGroupType } from "../admin/number_groups/types";
import { getGroupType } from "./categories";
import { endOfFnForType } from "./data";
import { CALL_NOS_QUERY, CallNoDataType } from "./queries";

type InfoModalProps = {
  numberGroup: NumberGroupType;
  mainGroup: MainGroupType;
  date: Date;
  onClose: () => void;
};

export default function InfoModal({ numberGroup, mainGroup, date, onClose }: InfoModalProps) {
  const session = useAppSelector(sessionSelector);
  const { start, stop } = useAppSelector((state) => state.reachability);
  const groupType = getGroupType(parseISO(start), parseISO(stop));
  const endFn = endOfFnForType(groupType);

  const { data } = useQuery<CallNoDataType>(CALL_NOS_QUERY, {
    variables: {
      numberGroupId: numberGroup.id,
      mainGroupId: mainGroup.id,
      start: date,
      stop: endFn(date),
      customerId: session.customer!.id,
    },
  });

  const calls = (data?.callNumbers || []).reduce(
    (acc, call) => {
      if (!acc[call.number]) acc[call.number] = { connected: 0, busy: 0, failed: 0 };
      acc[call.number][call.result] += 1;
      return acc;
    },
    {} as { [key: string]: { [key: string]: number } },
  );

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Calls für die Gruppe {numberGroup.name} / {mainGroup.name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Es wurden {data?.callNumbers.length || 0} Calls gefunden:</p>

        <ul className="TR-number-list">
          {Object.keys(calls)
            .sort()
            .map((no) => (
              <li key={no}>
                {no || <em>unbekannte Nummer</em>}:
                <ul>
                  {calls[no].connected > 0 && <li>{calls[no].connected} × angenommen</li>}
                  {calls[no].busy > 0 && <li>{calls[no].busy} × besetzt</li>}
                  {calls[no].failed > 0 && <li>{calls[no].failed} × abgebrochen</li>}
                </ul>
              </li>
            ))}
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <DoneButton onClick={onClose}>schließen</DoneButton>
      </Modal.Footer>
    </Modal>
  );
}
