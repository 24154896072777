import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Icon from "react-icons-kit";
import { ic_warning } from "react-icons-kit/md";

import { TTButtonProps } from "./types";

export default function ConfirmButton(props: TTButtonProps) {
  const [clicked, setClicked] = useState(false);

  function handleClick(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!clicked) {
      setClicked(true);
      return;
    }

    setClicked(false);
    if (props.onClick) props.onClick(ev);
  }

  function handleBlur(ev: React.FocusEvent<HTMLButtonElement>) {
    if (clicked) setClicked(false);
    if (props.onBlur) props.onBlur(ev);
  }

  return (
    <Button {...props} variant="danger" onClick={handleClick} onBlur={handleBlur}>
      {clicked ? (
        <>
          <Icon icon={ic_warning} /> Sind Sie sicher?
        </>
      ) : (
        props.children
      )}
    </Button>
  );
}
