import React from "react";

import { format } from "date-fns";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useAppSelector } from "../../hooks";
import { formatPercentage } from "../../utils/numberUtils";
import { MainGroupType } from "../admin/main_groups/types";
import { NumberGroupType } from "../admin/number_groups/types";
import { GroupType } from "./categories";
import { getDataByCategories } from "./data";
import { ReachabilityDateType } from "./queries";

type ChartProps = {
  data: ReachabilityDateType[];
  categories: Date[];
  dateFormat: string;
  groupType: GroupType;
};

const nullOrVal = (val: number | undefined, row: ReachabilityDateType | null) => {
  if (typeof val === "undefined" || !row) return null;
  if (row.connected + row.connectedElsewhere + row.busy + row.failed === 0) return null;

  return val;
};

const optionsForChart = (
  data: (ReachabilityDateType | null)[],
  dateFormat: string,
  categories: Date[],
  numberGroup: NumberGroupType | undefined | null,
  mainGroup: MainGroupType | undefined | null,
): Highcharts.Options => ({
  title: { text: numberGroup && mainGroup ? `${numberGroup.name} / ${mainGroup.name}` : undefined },
  xAxis: {
    categories: categories.map((v) => format(v, dateFormat)),
  },
  credits: {
    enabled: false,
  },
  yAxis: [
    { title: { text: "Calls" } },
    {
      title: { text: "Erreichbarkeit" },
      opposite: true,
      min: 0,
      max: 100,
      labels: { format: "{value} %" },
    },
  ],
  series: [
    {
      type: "column",
      name: "angenommen in Gruppe",
      data: data.map((v) => nullOrVal(v?.connected, v)),
      stack: "connected",
      stacking: "normal",
    },
    {
      type: "column",
      name: "angenommen außerhalb Gruppe",
      data: data.map((v) => nullOrVal(v?.connectedElsewhere, v)),
      stack: "connected",
      stacking: "normal",
    },
    {
      type: "column",
      name: "nicht angenommen",
      data: data.map((v) => nullOrVal(v?.failed, v)),
      stack: "not_connected",
      stacking: "normal",
    },
    {
      type: "column",
      name: "besetzt",
      data: data.map((v) => nullOrVal(v?.busy, v)),
      stack: "not_connected",
      stacking: "normal",
    },
    {
      type: "spline",
      name: "Erreichbarkeit in Gruppe",
      data: data.map((v) => nullOrVal(v?.reachabilityInGroup, v)),
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return `${this.series.name}: <b>${formatPercentage(this.y || 0)}</b>`;
        },
      },
    },
    {
      type: "spline",
      name: "Erreichbarkeit",
      data: data.map((v) => nullOrVal(v?.reachabilityElsewhere, v)),
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return `${this.series.name}: <b>${formatPercentage(this.y || 0)}</b>`;
        },
      },
    },
  ],
});

function Chart({ data, categories, dateFormat, groupType }: ChartProps) {
  const { mainGroup, numberGroup } = useAppSelector((state) => state.reachability);
  const dataByCategories = getDataByCategories(data, categories, groupType);

  return (
    <div className="TR-reachability-chart">
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsForChart(dataByCategories, dateFormat, categories, numberGroup, mainGroup)}
      />
    </div>
  );
}

export default React.memo(Chart);
