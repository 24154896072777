import { useQuery } from "@apollo/client";
import { nanoid } from "@reduxjs/toolkit";

import { useFormikContext } from "formik";

import { FormValues, NumberGroupNumberValues } from ".";
import DeleteButton from "../../../../containers/buttons/DeleteButton";
import NewButton from "../../../../containers/buttons/NewButton";
import ErrorMessage from "../../../../containers/Forms/ErrorMessage";
import Input from "../../../../containers/Forms/Input";
import Select from "../../../../containers/Forms/Select";
import { useAppSelector } from "../../../../hooks";
import { sessionSelector } from "../../../../reducers";
import { MAIN_GROUP_LIST_QUERY } from "../../main_groups/queries";
import { MainGroupsDataType } from "../../main_groups/types";

export default function Numbers() {
  const session = useAppSelector(sessionSelector);
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const { data } = useQuery<MainGroupsDataType>(MAIN_GROUP_LIST_QUERY, {
    variables: { customerId: session.customer!.id, limit: 0, offset: 0 },
  });

  function addNumber() {
    const newNumber: NumberGroupNumberValues = { number: "", mainGroupId: "", key: nanoid() };
    setFieldValue("numbers", [...values.numbers, newNumber], false);
  }

  function removeNumber(number: NumberGroupNumberValues) {
    const newNumbers = values.numbers.filter((num) => num.key !== number.key);
    setFieldValue("numbers", newNumbers, false);
  }

  const options = data?.mainGroups.map((group) => (
    <option key={group.id} value={group.id}>
      {group.name}
    </option>
  ));

  return (
    <fieldset>
      <legend>Nummern</legend>

      <ul className="TR-number-group-form-number-list">
        {values.numbers.map((number, i) => (
          <li key={number.key}>
            <div>
              <Input name={`numbers.${i}.number`} />
              <ErrorMessage name={`numbers.${i}.number`} />
            </div>

            <div>
              <Select name={`numbers.${i}.mainGroupId`}>
                <option></option>
                {options}
              </Select>
              <ErrorMessage name={`numbers.${i}.mainGroupId`} />
            </div>

            <DeleteButton onClick={() => removeNumber(number)} />
          </li>
        ))}
      </ul>

      <p>
        <NewButton variant="secondary" size="sm" onClick={addNumber}>
          Nummer hinzufügen
        </NewButton>
      </p>
    </fieldset>
  );
}
