import { useMutation } from "@apollo/client";

import { Form, Formik, FormikHelpers } from "formik";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Icon from "react-icons-kit";
import { ic_lock_open } from "react-icons-kit/md";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import ConfirmButton from "../../../containers/buttons/ConfirmButton";
import FormActionsContainer from "../../../containers/FormActionsContainer";
import Checkbox from "../../../containers/Forms/Checkbox";
import ErrorMessage from "../../../containers/Forms/ErrorMessage";
import Input from "../../../containers/Forms/Input";
import Select from "../../../containers/Forms/Select";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { adminUsersPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addErrorFlash, addSuccessFlash } from "../../Flash/flashReducer";
import Profiles from "./Profiles";
import { DISABLE_OTP_FOR_USER_MUTATION } from "./queries";
import type { DisableOtpForUserDataType, UserType } from "./types";

type FormProps = {
  user?: UserType;
  onSave: (values: FormValues) => void;
};

export type FormValues = {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  role: "ADMIN" | "USER";
  active: boolean;
  profiles: string[];
};

const initialValues = (user: UserType | undefined): FormValues => ({
  email: user?.email || "",
  password: "",
  firstname: user?.firstname || "",
  lastname: user?.lastname || "",
  role: user?.role || "USER",
  active: user?.active || true,
  profiles: (user?.profiles || []).map(({ id }) => id),
});

const validationSchema = Yup.object({
  email: Yup.string().required("Bitte geben Sie eine Email-Adresse an!"),
  password: Yup.string()
    .required("Bitte geben Sie ein Passwort an!")
    .min(12, "Das Passwort muss mindestens 12 Zeichen lang sein!"),
  role: Yup.string().required("Bitte geben Sie eine Rolle an!").oneOf(["ADMIN", "USER"]),
  firstname: Yup.string().required("Bitte geben Sie einen Vornamen an!"),
  lastname: Yup.string().required("Bitte geben Sie einen Nachnamena an!"),
});

export default function RelForm({ user, onSave }: FormProps) {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);

  const [disableOtpMutation] = useMutation<DisableOtpForUserDataType>(DISABLE_OTP_FOR_USER_MUTATION);

  async function handleSubmit(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) {
    setSubmitting(true);

    try {
      await onSave(values);
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
      setSubmitting(false);
    }
  }

  async function disableOtp() {
    try {
      await disableOtpMutation({ variables: { customerId: session.customer?.id, id: user?.id } });
      dispatch(addSuccessFlash("Die 2-Faktor-Autentifizierung wurde erfolgreich deaktiviert!"));
    } catch (e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
      console.error(e);
    }
  }

  return (
    <Formik initialValues={initialValues(user)} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(bag) => (
        <Form>
          <FormGroup>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input name="email" id="email" />
            <ErrorMessage name="email" />
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="password">Passwort</FormLabel>
            <Input name="password" id="password" type="password" />
            <ErrorMessage name="password" />
          </FormGroup>

          <FormGroup>
            <Checkbox label="aktiv" name="active" id="active" />
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="role">Rolle</FormLabel>
            <Select name="role" id="role">
              <option />
              <option value="ADMIN">Admin</option>
              <option value="USER">Benutzer</option>
            </Select>
            <ErrorMessage name="role" />
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="firstname">Vorname</FormLabel>
            <Input name="firstname" id="firstname" />
            <ErrorMessage name="firstname" />
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor="lastname">Nachname</FormLabel>
            <Input name="lastname" id="lastname" />
            <ErrorMessage name="lastname" />
          </FormGroup>

          <Profiles />

          <FormActionsContainer>
            <Button type="submit">speichern</Button>
            <Button as={Link as any} variant="secondary" to={adminUsersPath(session)}>
              abbrechen
            </Button>

            {user?.otp && (
              <ConfirmButton onClick={disableOtp}>
                <Icon icon={ic_lock_open} /> 2-Faktor-Autentifizierung deaktivieren
              </ConfirmButton>
            )}
          </FormActionsContainer>
        </Form>
      )}
    </Formik>
  );
}
