import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type NumberGroupsReducerState = {
  searchTerm: undefined | string;
};

const initialState: NumberGroupsReducerState = {
  searchTerm: undefined,
};

const numberGroupsReducer = createSlice({
  name: "numberGroups",
  initialState,
  reducers: {
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload === "" ? undefined : action.payload;
    },
  },
});

export const { setSearchTerm } = numberGroupsReducer.actions;
export default numberGroupsReducer.reducer;
