import { Navigate, Route, Routes } from "react-router-dom";

import { useAppSelector } from "../../hooks";
import { adminUsersPath } from "../../paths";
import { sessionSelector } from "../../reducers";
import AlertsInterface from "./alerts";
import MainGroupInterface from "./main_groups";
import NumberGroupInterface from "./number_groups";
import ProfilesInterface from "./profiles";
import UsersInterface from "./users";

export default function AdminInterface() {
  const session = useAppSelector(sessionSelector);

  return (
    <Routes>
      <Route path="users/*" element={<UsersInterface />} />
      <Route path="maingroups/*" element={<MainGroupInterface />} />
      <Route path="numbergroups/*" element={<NumberGroupInterface />} />
      <Route path="profiles/*" element={<ProfilesInterface />} />
      <Route path="alerts/*" element={<AlertsInterface />} />

      <Route path="/" element={<Navigate to={adminUsersPath(session)} />} />
    </Routes>
  );
}
