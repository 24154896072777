import { Modal } from "react-bootstrap";

import DoneButton from "../../containers/buttons/DoneButton";

type PropsType = {
  show: boolean;
  onHide: () => void;
  qrCode: string | null | undefined;
};

export default function EnrollOtpModal({ show, onHide, qrCode }: PropsType) {
  return (
    <Modal show={show} onHide={onHide} size="lg" className="TR-enroll-otp-modal">
      <Modal.Header closeButton>
        <Modal.Title>2-Faktor-Authentifizierung aktivieren</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <img src={`data:image/png;base64,${qrCode}`} alt="" />

        <p>Bitte importieren Sie diesen QR-Code in Ihre Authenticator-App (z.B. Google Authenticator).</p>
      </Modal.Body>

      <Modal.Footer>
        <DoneButton onClick={onHide}>fertig</DoneButton>
      </Modal.Footer>
    </Modal>
  );
}
