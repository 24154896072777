import Button from "react-bootstrap/Button";
import Icon from "react-icons-kit";
import { ic_cancel } from "react-icons-kit/md";

import { TTButtonProps } from "./types";

export default function CancelButton(props: TTButtonProps) {
  return (
    <Button variant="secondary" {...props}>
      <Icon icon={props.icon || ic_cancel} /> {props.children || "abbrechen"}
    </Button>
  );
}
