import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setSearchTerm } from "./userReducer";

export default function SearchForm() {
  const dispatch = useAppDispatch();
  const { searchTerm } = useAppSelector((state) => state.users);
  const [term, setTerm] = useState(searchTerm || "");

  function updateTerm(ev: React.ChangeEvent<HTMLInputElement>) {
    setTerm(ev.target.value);
  }

  useEffect(() => {
    const tm = window.setTimeout(() => dispatch(setSearchTerm(term)), 500);
    return () => window.clearTimeout(tm);
  }, [term, dispatch]);

  return (
    <Form className="form-inline">
      <Form.Label htmlFor="search" visuallyHidden>
        Suchbegriff
      </Form.Label>
      <Form.Control
        type="search"
        className="mb-2 mr-sm-2"
        placeholder="Suche…"
        id="search"
        value={term}
        onChange={updateTerm}
      />
    </Form>
  );
}
