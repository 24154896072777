import { gql } from "@apollo/client";

import { MAIN_GROUP_FRAGMENT } from "../admin/main_groups/queries";
import { MainGroupType } from "../admin/main_groups/types";
import { NUMBER_GROUP_FRAGMENT } from "../admin/number_groups/queries";
import { NumberGroupType } from "../admin/number_groups/types";

export type ReachabilityType = {
  date: string;
  connected: number;
  connectedElsewhere: number;
  failed: number;
  busy: number;
  reachabilityInGroup: number;
  reachabilityElsewhere: number;
};

export type ReachabilityRowType = ReachabilityType & {
  numberGroupId: string;
  mainGroupId: string;

  numberGroup: NumberGroupType;
  mainGroup: MainGroupType;
};

export type ReachabilityDateType = {
  date: Date;
  connected: number;
  connectedElsewhere: number;
  failed: number;
  busy: number;
  reachabilityInGroup: number;
  reachabilityElsewhere: number;
};

export type ReachabilityDataType = {
  reachability: ReachabilityRowType[];
};

export type MyNumberGroupsData = {
  myNumberGroups: NumberGroupType[];
};

export type MyMainGroupsData = {
  myMainGroups: NumberGroupType[];
};

export type CountCallsData = {
  countCalls: {
    count: number;
  };
};

export const REACHABILITY_QUERY = gql`
  query (
    $customerId: ID!
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
    $numberGroups: [ID]
    $mainGroups: [ID]
    $useDashboard: Boolean
  ) {
    reachability(
      customerId: $customerId
      start: $start
      stop: $stop
      numberGroups: $numberGroups
      mainGroups: $mainGroups
      useDashboard: $useDashboard
    ) {
      date
      connected
      connectedElsewhere
      failed
      busy
      reachabilityInGroup
      reachabilityElsewhere
      numberGroupId
      mainGroupId
    }
  }
`;

export const COUNT_CALLS_QUERY = gql`
  query (
    $customerId: ID!
    $start: NaiveDateTime!
    $stop: NaiveDateTime!
    $numberGroups: [ID]
    $mainGroups: [ID]
    $useDashboard: Boolean
  ) {
    countCalls(
      customerId: $customerId
      start: $start
      stop: $stop
      numberGroups: $numberGroups
      mainGroups: $mainGroups
      useDashboard: $useDashboard
    ) {
      count
    }
  }
`;

export const NUMBER_GROUPS_QUERY = gql`
  query ($customerId: ID!) {
    myNumberGroups(customerId: $customerId) {
      ...NumberGroupFragment

      numbers {
        id
        number
      }
    }
  }
  ${NUMBER_GROUP_FRAGMENT}
`;

export const MAIN_GROUPS_QUERY = gql`
  query ($customerId: ID!) {
    myMainGroups(customerId: $customerId) {
      ...MainGroupFragment
    }
  }
  ${MAIN_GROUP_FRAGMENT}
`;

export const DASHBOARD_GET_QUERY = gql`
  query ($customerId: ID!) {
    dashboardConfig(customerId: $customerId) {
      id
      numberGroups {
        id
        name
      }

      mainGroups {
        id
        name
      }
    }
  }
`;

export type CallNoType = {
  id: string;
  date: string;
  number: string;
  result: string;
};

export type CallNoDataType = {
  callNumbers: CallNoType[];
};

export const CALL_NOS_QUERY = gql`
  query ($customerId: ID!, $numberGroupId: ID!, $mainGroupId: ID!, $start: NaiveDateTime!, $stop: NaiveDateTime!) {
    callNumbers(
      customerId: $customerId
      numberGroupId: $numberGroupId
      mainGroupId: $mainGroupId
      start: $start
      stop: $stop
    ) {
      id
      date
      number
      result
    }
  }
`;
