import { Route, Routes } from "react-router-dom";

import useSubnav from "../../../hooks/useSubnav";
import useTitle from "../../../hooks/useTitle";
import Edit from "./Edit";
import List from "./List";
import New from "./New";

export default function MainGroupInterface() {
  useTitle("Standortverwaltung");
  useSubnav("admin/maingroups");

  return (
    <Routes>
      <Route path="new" element={<New />} />
      <Route path=":id/edit" element={<Edit />} />
      <Route path="/" element={<List />} />
    </Routes>
  );
}
