import { getIn, useFormikContext } from "formik";

import UserSelector, { OptionType, TRActionType, TROptionValueType } from "../../components/UserSelector";

export default function FormUserSelector(props: any) {
  const context = useFormikContext();
  const value = getIn(context.values, props.name);

  function handleChange(value: TROptionValueType, action: TRActionType) {
    if (props.isMulti) {
      context.setFieldValue(
        props.name,
        ((value as OptionType[] | null) || []).map((o) => o.value),
      );
    } else {
      context.setFieldValue(props.name, (value as OptionType | undefined) || "");
    }
    // context.handleChange();
  }

  return <UserSelector {...props} selected={value} onChange={handleChange} onBlur={context.handleBlur} />;
}
