import FormUserSelector from "../../../../containers/Forms/FormUserSelector";

export default function Users() {
  return (
    <fieldset>
      <legend>Benutzer</legend>

      <FormUserSelector name="users" isMulti />
    </fieldset>
  );
}
