import Alert from "react-bootstrap/Alert";

import { useAppDispatch } from "../../hooks";
import { FlashMessage, removeFlashMessage } from "./flashReducer";

type FlashProps = {
  flash: FlashMessage;
};

const CLASSES: { [key: string]: "danger" | "success" | "info" } = {
  error: "danger",
  success: "success",
  notice: "info",
};

export default function Flash({ flash }: FlashProps) {
  const dispatch = useAppDispatch();

  function toggle() {
    dispatch(removeFlashMessage(flash.id));
  }

  const color = CLASSES[flash.type];

  return (
    <Alert variant={color} onClose={toggle} dismissible>
      {flash.text}
    </Alert>
  );
}
