import "./styles.scss";

import React from "react";

import { useMutation, useQuery } from "@apollo/client";

import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import ActionColumn from "../../../containers/ActionColumn";
import DeleteButton from "../../../containers/buttons/DeleteButton";
import EditButton from "../../../containers/buttons/EditButton";
import Loading from "../../../containers/Loading";
import NoDataRow from "../../../containers/NoDataRow";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { editAdminUserPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { relativeTime } from "../../../utils/dateUtils";
import FetchMore from "../../FetchMore";
import { addErrorFlash, addSuccessFlash } from "../../Flash/flashReducer";
import { USER_DELETE_MUTATION, USER_LIST_QUERY } from "./queries";
import SearchForm from "./SearchForm";
import type { UsersDataType, UserType } from "./types";

export default function List() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { searchTerm: search } = useAppSelector((state) => state.users);

  const { data, fetchMore, loading } = useQuery<UsersDataType>(USER_LIST_QUERY, {
    variables: { limit: 25, offset: 0, customerId: session.customer!.id, search },
  });

  const [deleteUserMutation] = useMutation(USER_DELETE_MUTATION, {
    update(cache, { data: { deleteUser } }) {
      const users = cache.readQuery<UsersDataType>({
        query: USER_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
      })!.users;

      cache.writeQuery({
        query: USER_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
        data: { users: users.filter((u) => u.id !== deleteUser.id) },
      });
    },
  });

  async function deleteUser(user: UserType) {
    try {
      await deleteUserMutation({ variables: { id: user.id, customerId: session.customer!.id } });
      dispatch(addSuccessFlash("Der Benutzer wurde erfolgreich gelöscht!"));
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
    }
  }

  return (
    <>
      {!data?.users && <Loading fullscreen backdrop />}

      <SearchForm />

      <Table className="TT-reachability-users-list">
        <thead>
          <tr>
            <th>Nachname</th>
            <th>Vorname</th>
            <th>Email</th>
            <th>aktiv</th>
            <th>erstellt</th>
            <th>bearbeitet</th>
            <th />
          </tr>
        </thead>

        <tbody>
          <NoDataRow data={data?.users} cols={7} />

          {data?.users.map((user) => {
            const profiles = user.profiles.map((profile) => profile.name).join(", ");
            return (
              <React.Fragment key={user.id}>
                <tr className="first">
                  <td>{user.lastname}</td>
                  <td>{user.firstname}</td>
                  <td>{user.email}</td>
                  <td>{user.active ? "ja" : "nein"}</td>
                  <td>{relativeTime(user.insertedAt)}</td>
                  <td>{relativeTime(user.updatedAt)}</td>

                  <ActionColumn>
                    <EditButton size="sm" as={Link as any} to={editAdminUserPath(session, user)} />
                    <DeleteButton size="sm" onClick={() => deleteUser(user)} />
                  </ActionColumn>
                </tr>
                <tr className="second">
                  <td colSpan={7}>Profile: {profiles || <em>kein Profil zugewiesen</em>}</td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>

      <FetchMore fetchMore={fetchMore} data={data} loading={loading} dataKey="users" />
    </>
  );
}
