import React from "react";

import { useMutation, useQuery } from "@apollo/client";

import { useNavigate, useParams } from "react-router-dom";

import Loading from "../../../containers/Loading";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import { adminProfilesPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addSuccessFlash } from "../../Flash/flashReducer";
import { PROFILE_MUTATION, PROFILE_QUERY } from "./queries";
import RelForm, { FormValues } from "./RelForm";
import { ProfileDataType } from "./types";

export default function Edit() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);

  const { id } = useParams<{ id: string }>();
  const { data } = useQuery<ProfileDataType>(PROFILE_QUERY, { variables: { id, customerId: session.customer!.id } });
  const [mainGroupMutationMutation] = useMutation(PROFILE_MUTATION);
  const navigate = useNavigate();

  useTitle(`Profil „${data?.profile?.name || ""}“ bearbeiten`);

  async function onSave(values: FormValues) {
    await mainGroupMutationMutation({
      variables: {
        customerId: session.customer!.id,
        id: data!.profile.id,
        profile: values,
      },
    });

    dispatch(addSuccessFlash("Das Profil wurde erfolgreich editiert!"));
    navigate(adminProfilesPath(session));
  }

  if (!data?.profile) return <Loading stretch />;

  return (
    <React.Fragment>
      <h2>Profil „{data.profile.name}” bearbeiten</h2>

      <RelForm profile={data.profile} onSave={onSave} />
    </React.Fragment>
  );
}
