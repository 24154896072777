import Button from "react-bootstrap/Button";
import Icon from "react-icons-kit";
import { ic_save } from "react-icons-kit/md";

import { TTButtonProps } from "./types";

export default function SaveButton(props: TTButtonProps) {
  return (
    <Button variant="primary" {...props}>
      <Icon icon={props.icon || ic_save} /> {props.children || "speichern"}
    </Button>
  );
}
