import React from "react";

import { FastField, Field, getIn, useFormikContext } from "formik";
import PropTypes from "prop-types";
// import { Input as ReactstrapInput, InputProps } from "reactstrap";
import FormControl, { FormControlProps } from "react-bootstrap/FormControl";

type FormInputProps = {
  fast?: boolean;
  validate?: boolean;
  name: string;
  children?: React.ReactNode;
  step?: number;
  min?: number;
  max?: number;
} & FormControlProps;

export const fieldInvalid = (errors: Object, touched: Object, path: string) =>
  !!(getIn(errors, path) && getIn(touched, path));
export const fieldValid = (errors: Object, touched: Object, path: string) =>
  !!(!getIn(errors, path) && getIn(touched, path));

function Input(props: FormInputProps) {
  const formik = useFormikContext();
  const tag = props.fast ? FastField : Field;

  const cleanProps = { ...props };

  // delete cleanProps.tag;
  delete cleanProps.fast;

  if (props.validate) {
    const { errors, touched } = formik;
    const name = props.name;

    return (
      <FormControl
        as={tag}
        {...cleanProps}
        // validate={props.validationCallback}
        isValid={fieldValid(errors, touched, name)}
        isInvalid={fieldInvalid(errors, touched, name)}
      />
    );
  }

  return <FormControl as={tag} {...cleanProps} />;
}

Input.propTypes = {
  validate: PropTypes.bool,
  fast: PropTypes.bool,
  name: PropTypes.string.isRequired,
  validationCallback: PropTypes.func,
};

Input.defaultProps = {
  validate: true,
  fast: true,
};

export default Input;
