import React from "react";

import { useQuery } from "@apollo/client";

import { endOfDay, endOfMonth, formatISO, parseISO, startOfDay, startOfMonth } from "date-fns";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import Select from "react-select";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { DashboardConfigType } from "../DashboardConfig/queries";
import DatePickerInput from "./DatePickerInput";
import { MAIN_GROUPS_QUERY, MyMainGroupsData, MyNumberGroupsData, NUMBER_GROUPS_QUERY } from "./queries";
import {
  setSelectedMainGroups,
  setSelectedNumberGroups,
  setStart,
  setStop,
  toggleUseDashboard,
} from "./reachabilityReducer";

type ReachabilityFormProps = {
  config: DashboardConfigType | undefined;
};

export default function ReachabilityForm({ config }: ReachabilityFormProps) {
  const { start, stop, selectedTab, selectedNumberGroups, selectedMainGroups, useDashboard } = useAppSelector(
    (state) => state.reachability,
  );
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);

  const { data: numberGroupsData } = useQuery<MyNumberGroupsData>(NUMBER_GROUPS_QUERY, {
    variables: { customerId: session.customer!.id },
  });
  const { data: mainGroupsData } = useQuery<MyMainGroupsData>(MAIN_GROUPS_QUERY, {
    variables: { customerId: session.customer!.id },
  });

  function setStartDate(date: Date | null) {
    if (date) {
      dispatch(setStart(formatISO(startOfDay(date))));
    }
  }

  function setStopDate(date: Date | null) {
    if (date) {
      dispatch(setStop(formatISO(endOfDay(date))));
    }
  }

  function setMonth(date: Date | null) {
    if (date) {
      dispatch(setStart(formatISO(startOfMonth(date))));
      dispatch(setStop(formatISO(endOfMonth(date))));
    }
  }

  function updateNumberGroups(values: any) {
    dispatch(setSelectedNumberGroups((values || []).map((v: any) => v.numberGroup)));
  }

  function updateMainGroups(values: any) {
    dispatch(setSelectedMainGroups((values || []).map((v: any) => v.mainGroup)));
  }

  function changeDashboard() {
    dispatch(toggleUseDashboard());
  }

  return (
    <Form className="TR-reachability-form">
      {config?.id && (
        <Form.Group>
          <Form.Check
            label="Dashboard verwenden"
            id="use-dashboard"
            checked={useDashboard}
            onChange={changeDashboard}
          />
        </Form.Group>
      )}

      {(!config?.id || !useDashboard) && (
        <React.Fragment>
          <Form.Group>
            <Form.Label htmlFor="number-groups" visuallyHidden>
              Nummerngruppen
            </Form.Label>

            <Select
              id="number-groups"
              placeholder="Nummerngruppen wählen…"
              options={(numberGroupsData?.myNumberGroups || [])
                .map((ng) => ({
                  value: ng.id,
                  label: ng.name,
                  numberGroup: ng,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              isMulti
              isClearable
              onChange={updateNumberGroups}
              value={selectedNumberGroups
                .map((ng) => ({
                  value: ng.id,
                  label: ng.name,
                  numberGroup: ng,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="main-groups" visuallyHidden>
              Standorte
            </Form.Label>

            <Select
              id="main-groups"
              placeholder="Standorte wählen…"
              options={(mainGroupsData?.myMainGroups || [])
                .map((mg) => ({
                  value: mg.id,
                  label: mg.name,
                  mainGroup: mg,
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              isMulti
              isClearable
              onChange={updateMainGroups}
              value={selectedMainGroups
                .map((mg) => ({ value: mg.id, label: mg.name, mainGroup: mg }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
          </Form.Group>
        </React.Fragment>
      )}

      {selectedTab === "month" && (
        <Form.Group>
          <Form.Label htmlFor="month" visuallyHidden>
            Monat
          </Form.Label>

          <DatePicker
            selected={parseISO(start)}
            onChange={setMonth}
            dateFormat="LLLL yyyy"
            className="form-control mb-2 mr-sm-2"
            showMonthYearPicker
            showFullMonthYearPicker
            id="month"
            customInput={<DatePickerInput />}
          />
        </Form.Group>
      )}

      {selectedTab === "range" && (
        <>
          <Form.Group>
            <Form.Label htmlFor="startDate" visuallyHidden>
              Start-Datum
            </Form.Label>

            <DatePicker
              id="startDate"
              selected={parseISO(start)}
              onChange={setStartDate}
              className="form-control mb-2 mr-sm-2"
              dateFormat="dd. LLLL yyyy"
              maxDate={parseISO(stop)}
              customInput={<DatePickerInput />}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="stopDate" visuallyHidden>
              Ende-Datum
            </Form.Label>

            <DatePicker
              selected={parseISO(stop)}
              onChange={setStopDate}
              className="form-control mb-2 mr-sm-2"
              dateFormat="dd. LLLL yyyy"
              minDate={parseISO(start)}
              customInput={<DatePickerInput />}
            />
          </Form.Group>
        </>
      )}
    </Form>
  );
}
