import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";

import { useQuery } from "@apollo/client";

import { parseISO } from "date-fns";
import Nav from "react-bootstrap/Nav";
import Icon from "react-icons-kit";
import { ic_cached } from "react-icons-kit/md";
import { Link } from "react-router-dom";

import Spinner from "../../containers/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useTitle from "../../hooks/useTitle";
import { sessionSelector } from "../../reducers";
import { DashboardConfigData } from "../DashboardConfig/queries";
import { dateFormatForTab, getCategories, getGroupType } from "./categories";
import Chart from "./Chart";
import { aggregateData } from "./data";
import {
  COUNT_CALLS_QUERY,
  CountCallsData,
  DASHBOARD_GET_QUERY,
  REACHABILITY_QUERY,
  ReachabilityDataType,
} from "./queries";
import ReachabilityForm from "./ReachabilityForm";
import ReachabilityListTable from "./ReachabilityListTable";
import { DateTabType, setSelectedTab } from "./reachabilityReducer";
import ReachabilityTable from "./ReachabilityTable";
import { shouldShowListTable } from "./utils";

const headings = {
  today: "Heute",
  yesterday: "Gestern",
  "7days": "7 Tage",
  "30days": "30 Tage",
  month: "Monat",
  range: "freier Zeitraum",
};

export default function ReachabilityInterface() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { start, stop, selectedTab, numberGroup, mainGroup, selectedNumberGroups, selectedMainGroups, useDashboard } =
    useAppSelector((state) => state.reachability);

  useTitle(headings[selectedTab]);

  const variables = {
    start: parseISO(start),
    stop: parseISO(stop),
    numberGroups: selectedNumberGroups.length > 0 ? selectedNumberGroups.map((ng) => ng.id) : undefined,
    mainGroups: selectedMainGroups.length > 0 ? selectedMainGroups.map((mg) => mg.id) : undefined,
    useDashboard,
    customerId: session.customer!.id,
  };

  const {
    data,
    refetch: refetchData,
    loading: loadingData,
  } = useQuery<ReachabilityDataType>(REACHABILITY_QUERY, {
    variables,
  });
  const {
    data: sumData,
    refetch: refetchCount,
    loading: loadingCount,
  } = useQuery<CountCallsData>(COUNT_CALLS_QUERY, {
    variables,
  });

  const groupType = getGroupType(variables.start, variables.stop);
  const aggregatedData = aggregateData(data?.reachability || [], groupType, numberGroup, mainGroup);
  const { data: dashboardData } = useQuery<DashboardConfigData>(DASHBOARD_GET_QUERY, {
    variables: { customerId: session.customer!.id },
  });

  const sum = sumData?.countCalls.count || 0;
  const categories = getCategories(aggregatedData, variables.start, variables.stop);
  const dateFormat = dateFormatForTab(variables.start, variables.stop);

  function setRange(key: string | null) {
    if (key) {
      dispatch(setSelectedTab(key as DateTabType));
    }
  }

  function refresh() {
    refetchData();
    refetchCount();
  }

  return (
    <div className="TR-reachability-container">
      <h1>Erreichbarkeit</h1>

      <Nav variant="tabs" defaultActiveKey="today" onSelect={setRange} activeKey={selectedTab} className="mb-2">
        <Nav.Item>
          <Nav.Link as="button" className="TR-reachability-reload-button" disabled={loadingData || loadingCount}>
            {(loadingData || loadingCount) && <Spinner color="#000" />}
            {!loadingData && !loadingCount && <Icon icon={ic_cached} onClick={refresh} />}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="today">Heute</Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey="yesterday">Gestern</Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey="7days">7 Tage</Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey="30days">30 Tage</Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey="month">Monat</Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey="range">freier Zeitraum</Nav.Link>
        </Nav.Item>

        <Nav.Item className="ml-auto">
          <Nav.Link as={Link} to={`/${session.customer!.identifier}/config`}>
            Dashboard konfigurieren
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <h2>
        {headings[selectedTab]} ({sum} Calls)
      </h2>

      <ReachabilityForm config={dashboardData?.dashboardConfig} />

      {!data?.reachability?.length && (
        <p className="TR-reachability-no-data">
          Leider konnten keine Daten gefunden werden, die zu ihrer aktuellen Ansicht passen.
        </p>
      )}

      {!!data?.reachability?.length && (
        <>
          <ReachabilityTable data={data?.reachability || []} />

          <Chart data={aggregatedData} categories={categories} dateFormat={dateFormat} groupType={groupType} />

          {shouldShowListTable(
            numberGroup,
            mainGroup,
            selectedNumberGroups,
            useDashboard,
            dashboardData?.dashboardConfig,
          ) && (
            <ReachabilityListTable
              data={aggregateData(data?.reachability || [], groupType, numberGroup, mainGroup, false)}
              dateFormat={dateFormat}
              groupType={groupType}
            />
          )}
        </>
      )}
    </div>
  );
}
