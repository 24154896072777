import type { AlertType } from "../components/admin/alerts/types";
import type { MainGroupType } from "../components/admin/main_groups/types";
import type { NumberGroupType } from "../components/admin/number_groups/types";
import type { ProfileType } from "../components/admin/profiles/types";
import type { UserType } from "../components/admin/users/types";
import type { SessionState } from "../components/App/sessionReducer";

export const adminPath = (session: SessionState) => `/${session.customer!.identifier}/admin`;

export const adminUsersPath = (session: SessionState) => `${adminPath(session)}/users`;
export const newAdminUserPath = (session: SessionState) => `${adminPath(session)}/users/new`;
export const editAdminUserPath = (session: SessionState, user: UserType) =>
  `${adminPath(session)}/users/${user.id}/edit`;

export const adminMainGroupsPath = (session: SessionState) => `${adminPath(session)}/maingroups`;
export const newAdminMainGroupPath = (session: SessionState) => `${adminPath(session)}/maingroups/new`;
export const editAdminMainGroupPath = (session: SessionState, mainGroup: MainGroupType) =>
  `${adminPath(session)}/maingroups/${mainGroup.id}/edit`;

export const adminNumberGroupsPath = (session: SessionState) => `${adminPath(session)}/numbergroups`;
export const newAdminNumberGroupPath = (session: SessionState) => `${adminPath(session)}/numbergroups/new`;
export const editAdminNumberGroupPath = (session: SessionState, numberGroup: NumberGroupType) =>
  `${adminPath(session)}/numbergroups/${numberGroup.id}/edit`;

export const adminProfilesPath = (session: SessionState) => `${adminPath(session)}/profiles`;
export const newAdminProfilePath = (session: SessionState) => `${adminPath(session)}/profiles/new`;
export const editAdminProfilePath = (session: SessionState, profile: ProfileType) =>
  `${adminPath(session)}/profiles/${profile.id}/edit`;

export const adminAlertsPath = (session: SessionState) => `${adminPath(session)}/alerts`;
export const newAdminAlertPath = (session: SessionState) => `${adminPath(session)}/alerts/new`;
export const editAdminAlertPath = (session: SessionState, alert: AlertType) =>
  `${adminPath(session)}/alerts/${alert.id}/edit`;
