import React from "react";

import { useMutation, useQuery } from "@apollo/client";

import { useNavigate, useParams } from "react-router-dom";

import Loading from "../../../containers/Loading";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import { adminUsersPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addSuccessFlash } from "../../Flash/flashReducer";
import { USER_MUTATION, USER_QUERY } from "./queries";
import RelForm, { FormValues } from "./RelForm";
import { UserDataType } from "./types";

export default function Edit() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { id } = useParams<{ id: string }>();
  const { data } = useQuery<UserDataType>(USER_QUERY, { variables: { customerId: session.customer!.id, id } });
  const [userMutation] = useMutation(USER_MUTATION);
  const navigate = useNavigate();

  useTitle(`Benutzer ${data?.user?.firstname || ""} ${data?.user?.lastname} bearbeiten`);

  async function onSave(values: FormValues) {
    await userMutation({
      variables: {
        customerId: session.customer!.id,
        id: data!.user.id,
        user: values,
      },
    });

    dispatch(addSuccessFlash("Der Benutzer wurde erfolgreich editiert!"));
    navigate(adminUsersPath(session));
  }

  if (!data?.user) return <Loading stretch />;

  return (
    <React.Fragment>
      <h2>
        Benutzer {data.user.firstname} {data.user.lastname} bearbeiten
      </h2>

      <RelForm user={data.user} onSave={onSave} />
    </React.Fragment>
  );
}
