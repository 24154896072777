import { useQuery } from "@apollo/client";

import { useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import { FormValues } from ".";
import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { notEmpty } from "../../utils/dataUtils";
import { MainGroupType } from "../admin/main_groups/types";
import { MyMainGroupsData } from "../Reachability/queries";
import { MAIN_GROUPS_QUERY } from "./queries";

const hasNumberGroup = (mg: MainGroupType, ids: string[]) =>
  mg.numbers.find((number) => ids.includes(number.numberGroupId));

export default function MainGroups() {
  const session = useAppSelector(sessionSelector);
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { data } = useQuery<MyMainGroupsData>(MAIN_GROUPS_QUERY, { variables: { customerId: session.customer!.id } });

  const options = (data?.myMainGroups || [])
    .filter((mg) => values.numberGroups.length === 0 || hasNumberGroup(mg, values.numberGroups))
    .map((mg) => ({
      value: mg.id,
      label: mg.name,
      mainGroup: mg,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const value = values.mainGroups
    .map((id) => options.find(({ value }) => value === id))
    .filter(notEmpty)
    .sort((a, b) => a!.label.localeCompare(b!.label));

  function handleChange(value: any) {
    setFieldValue(
      "mainGroups",
      (value || []).map((v: any) => v.value),
    );
  }

  return (
    <Form.Group>
      <Form.Label>Standorte</Form.Label>
      <Select placeholder="Standorte auswählen…" isMulti options={options} value={value} onChange={handleChange} />
    </Form.Group>
  );
}
