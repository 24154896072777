import React from "react";

import { useMutation } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import { adminAlertsPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addSuccessFlash } from "../../Flash/flashReducer";
import { ALERT_MUTATION } from "./queries";
import RelForm, { FormValues } from "./RelForm";

export default function Edit() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const [alertMutation] = useMutation(ALERT_MUTATION);
  const navigate = useNavigate();

  useTitle("neuer Alarm");

  async function onSave(values: FormValues) {
    const emails = values.emails.map(({ email }) => email);
    await alertMutation({ variables: { alert: { ...values, emails }, customerId: session.customer!.id } });

    dispatch(addSuccessFlash("Der Alarm wurde erfolgreich erstellt!"));
    navigate(adminAlertsPath(session));
  }

  return (
    <React.Fragment>
      <h2>neuer Alarm</h2>

      <RelForm onSave={onSave} />
    </React.Fragment>
  );
}
