import { getIn, useFormikContext } from "formik";
import FormCheck, { FormCheckProps } from "react-bootstrap/esm/FormCheck";

type CheckboxProps = FormCheckProps & {
  name: string;
};

export default function Checkbox(props: CheckboxProps) {
  const context = useFormikContext();
  const checked = getIn(context.values, props.name);

  return <FormCheck onChange={context.handleChange} onBlur={context.handleBlur} checked={checked} {...props} />;
}
