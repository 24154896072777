import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link, useLocation } from "react-router-dom";

import { useAppSelector } from "../../hooks";
import { adminPath } from "../../paths";
import { sessionSelector } from "../../reducers";
import { isActive } from "./utils";

export default function MainSubnav() {
  const { pathname } = useLocation();
  const session = useAppSelector(sessionSelector);

  if (!session.user || session.user.role.toUpperCase() !== "ADMIN") return null;

  return (
    <ButtonGroup className="TR-subnav">
      <Button variant="secondary" as={Link as any} to={adminPath(session)} active={isActive(pathname, "/admin")}>
        Administration
      </Button>
    </ButtonGroup>
  );
}
