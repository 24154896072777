import { useState } from "react";

import { useMutation } from "@apollo/client";

import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { ENABLE_OTP_MUTATION, type EnableOtpDataType, SESSION_USER_FRAGMENT } from "../App/queries";
import { logoutUser, setUser } from "../App/sessionReducer";
import { addErrorFlash } from "../Flash/flashReducer";
import DisableOtpModal from "./DisableOtpModal";
import EnrollOtpModal from "./EnrollOtpModal";

export default function Header() {
  const [showEnrollOtpModal, setShowEnrollOtpModal] = useState(false);
  const [showDisableOtpModal, setShowDisableOtpModal] = useState(false);
  const [qrPng, setQrPng] = useState<string | null>(null);
  const session = useAppSelector(sessionSelector);
  const { user } = session;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [enableOtpMutation] = useMutation<EnableOtpDataType>(ENABLE_OTP_MUTATION, {
    update(cache, { data }) {
      if (!user || !data?.enableOtp) return;

      dispatch(setUser({ ...user, otp: false }));

      cache.writeFragment({
        id: cache.identify(user),
        fragment: SESSION_USER_FRAGMENT,
        data: {
          ...user,
          otp: false,
        },
      });
    },
  });

  function logout() {
    dispatch(logoutUser());
    navigate("/login");
  }

  async function enableOtp() {
    try {
      const { data } = await enableOtpMutation();

      if (!data?.enableOtp) {
        throw new Error("No data returned from enableOtpMutation");
      }

      setQrPng(data.enableOtp);
      setShowEnrollOtpModal(true);
    } catch (error) {
      dispatch(
        addErrorFlash(
          "Ein Fehler ist beim aktivieren der 2-Faktor-Authentifizierung aufgetreten. Bitte versuchen Sie es später erneut.",
        ),
      );
      console.error(error);
    }
  }

  return (
    <>
      <Navbar bg="light" expand="md" as="header" className="TR-page-header">
        <Navbar.Brand as={Link} to={`/${session.customer?.identifier || ""}`}>
          <img src="/logo.svg" alt="Termitel GmbH" />: Erreichbarkeit
        </Navbar.Brand>

        {user && (
          <Dropdown>
            <Dropdown.Toggle id="user-dropdown" className="user-button">
              {user.firstname} {user.lastname}
            </Dropdown.Toggle>

            <Dropdown.Menu id="user-dropdown">
              <Dropdown.Item as={Link} to={`/${session.customer!.identifier}/change-password`}>
                Passwort ändern
              </Dropdown.Item>

              {!user.otp && <Dropdown.Item onClick={enableOtp}>2-Faktor-Authentifizierung aktivieren</Dropdown.Item>}
              {user.otp && (
                <Dropdown.Item onClick={() => setShowDisableOtpModal(true)}>
                  2-Faktor-Authentifizierung deaktivieren
                </Dropdown.Item>
              )}

              <Dropdown.Item as="button" onClick={logout}>
                ausloggen
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Navbar>

      <EnrollOtpModal show={showEnrollOtpModal} qrCode={qrPng} onHide={() => setShowEnrollOtpModal(false)} />
      <DisableOtpModal show={showDisableOtpModal} onHide={() => setShowDisableOtpModal(false)} />
    </>
  );
}
