import { ApolloLink, from, HttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

import { getAuthorizationToken } from "../authorizationToken";
import { setContext } from "../sentry";

const URI = process.env.NODE_ENV === "development" ? "http://localhost:4000/graphql" : "/graphql";

const httpLink = new HttpLink({ uri: URI });
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAuthorizationToken();

  Sentry.addBreadcrumb({
    category: "graphql",
    message: `GraphQL ${operation.operationName}`,
    level: "info",
    data: {
      operationName: operation.operationName,
      variables: JSON.stringify(operation.variables),
    },
  });

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors && "filter" in graphQLErrors) {
    graphQLErrors
      .filter(({ message }) => message !== "not_found")
      .forEach(({ message, locations, path, extensions }) => {
        if (["login", "refresh"].includes(operation.operationName)) return;

        console.error(
          `[GraphQL error]: Message: ${message}, Location:`,
          locations,
          "Path:",
          path,
          "extensions",
          extensions,
          "Operation:",
          operation.operationName,
          operation,
        );

        if (process.env.NODE_ENV === "production") {
          const errorContext = {
            extensions: JSON.stringify(extensions, undefined, 2),
            locations: JSON.stringify(locations, undefined, 2),
            path: JSON.stringify(path, undefined, 2),
            operationName: operation.operationName,
            operation: JSON.stringify(path, undefined, 2),
            variables: JSON.stringify(operation.variables, undefined, 2),
          };

          const err = new Error(message);
          err.name = "graphQLError";
          if ("captureStackTrace" in Error) Error.captureStackTrace(err);

          Sentry.setTag("type", "graphql");

          Sentry.setContext("graphqlContext", errorContext);

          setContext();

          Sentry.captureException(err);
        }
      });
  }
});

const link = from([errorLink, authMiddleware.concat(httpLink)]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default client;
