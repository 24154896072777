import "./styles/index.scss";

import React from "react";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";

import de from "date-fns/locale/de";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import apolloClient from "./apollo";
import { getAuthorizationToken, setAuthorizationToken } from "./authorizationToken";
import App from "./components/App";
import { refreshUser, setUser } from "./components/App/sessionReducer";
import store from "./store";

registerLocale("de", de as any);
setDefaultLocale("de");

// import * as serviceWorker from "./serviceWorker";

const token = getAuthorizationToken();
if (token) {
  setAuthorizationToken(token);
  store.dispatch(refreshUser());
} else {
  store.dispatch(setUser(null));
}

const renderApp = (Component: () => JSX.Element) => {
  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <Component />
          </BrowserRouter>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>,
  );
};

renderApp(App);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    renderApp(NextApp);
  });
}

Sentry.init({
  dsn: "https://3114c70adfd7b6e69026d7315aef4329@o254619.ingest.us.sentry.io/4507848032256000",
  integrations: [Sentry.browserTracingIntegration()],
  enabled: process.env.NODE_ENV === "production",
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
  autoSessionTracking: false,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV,
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
