import "./Loading.scss";

import clsx from "clsx";

import Spinner from "../Spinner";

interface LoadingProps {
  backdrop?: boolean;
  fullscreen?: boolean;
  stretch?: boolean;
  translucent?: boolean;
}

export default function Loading({ backdrop, fullscreen, stretch, translucent }: LoadingProps) {
  const classList = clsx("TR-loading-screen", { backdrop, fullscreen, stretch, translucent, absolute: fullscreen });

  return (
    <div className={classList}>
      <Spinner color={backdrop ? "#fff" : "#000"} />
    </div>
  );
}
