import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PropsType = { [key: string]: any };
type LayoutState = {
  subSubnav: string | null;
  props: PropsType;
};

const initialState: LayoutState = {
  subSubnav: null,
  props: {},
};

const layoutReducer = createSlice({
  name: "layout",
  initialState,
  reducers: {
    addSubnav(state, action: PayloadAction<{ subSubNav: string; props: PropsType }>) {
      state.subSubnav = action.payload.subSubNav;
      state.props = action.payload.props;
    },
    removeSubnav(state) {
      state.subSubnav = null;
    },
  },
});

export const { addSubnav, removeSubnav } = layoutReducer.actions;
export default layoutReducer.reducer;
