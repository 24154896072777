import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Icon from "react-icons-kit";
import { ic_alarm, ic_business, ic_contact_phone, ic_person, ic_security } from "react-icons-kit/md";
import { Link, useLocation } from "react-router-dom";

import { useAppSelector } from "../../hooks";
import {
  adminAlertsPath,
  adminMainGroupsPath,
  adminNumberGroupsPath,
  adminProfilesPath,
  adminUsersPath,
} from "../../paths";
import { sessionSelector } from "../../reducers";
import { isActive } from "../Layout/utils";

function Linklist() {
  const { pathname } = useLocation();
  const session = useAppSelector(sessionSelector);

  return (
    <ButtonGroup>
      <Button
        as={Link as any}
        to={adminUsersPath(session)}
        variant="secondary"
        size="sm"
        active={isActive(pathname, "/admin/users")}
      >
        <Icon icon={ic_person} /> Benutzer
      </Button>

      <Button
        as={Link as any}
        to={adminMainGroupsPath(session)}
        variant="secondary"
        size="sm"
        active={isActive(pathname, "/admin/maingroups")}
      >
        <Icon icon={ic_business} /> Standorte
      </Button>

      <Button
        as={Link as any}
        to={adminNumberGroupsPath(session)}
        variant="secondary"
        size="sm"
        active={isActive(pathname, "/admin/numbergroups")}
      >
        <Icon icon={ic_contact_phone} /> Nummerngruppen
      </Button>

      <Button
        as={Link as any}
        to={adminProfilesPath(session)}
        variant="secondary"
        size="sm"
        active={isActive(pathname, "/admin/profiles")}
      >
        <Icon icon={ic_security} /> Profile
      </Button>

      <Button
        as={Link as any}
        to={adminAlertsPath(session)}
        variant="secondary"
        size="sm"
        active={isActive(pathname, "/admin/alerts")}
      >
        <Icon icon={ic_alarm} /> Alarme
      </Button>
    </ButtonGroup>
  );
}

export default React.memo(Linklist);
