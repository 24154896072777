import React from "react";

import { useMutation } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import { adminUsersPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addSuccessFlash } from "../../Flash/flashReducer";
import { USER_MUTATION } from "./queries";
import RelForm, { FormValues } from "./RelForm";

export default function Edit() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const [userMutation] = useMutation(USER_MUTATION);
  const navigate = useNavigate();

  useTitle("neuer Benutzer");

  async function onSave(values: FormValues) {
    await userMutation({ variables: { customerId: session.customer!.id, user: values } });

    dispatch(addSuccessFlash("Der Benutzer wurde erfolgreich erstellt!"));
    navigate(adminUsersPath(session));
  }

  return (
    <React.Fragment>
      <h2>neuer Benutzer</h2>

      <RelForm onSave={onSave} />
    </React.Fragment>
  );
}
