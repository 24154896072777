import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UserReducerState = {
  searchTerm: undefined | string;
};

const initialState: UserReducerState = {
  searchTerm: undefined,
};

const userReducer = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload === "" ? undefined : action.payload;
    },
  },
});

export const { setSearchTerm } = userReducer.actions;
export default userReducer.reducer;
