import "./style.scss";

import { nanoid } from "@reduxjs/toolkit";

import { Form, Formik, FormikHelpers } from "formik";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormText from "react-bootstrap/FormText";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import FormActionsContainer from "../../../../containers/FormActionsContainer";
import Checkbox from "../../../../containers/Forms/Checkbox";
import ErrorMessage from "../../../../containers/Forms/ErrorMessage";
import Input from "../../../../containers/Forms/Input";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { adminAlertsPath } from "../../../../paths";
import { sessionSelector } from "../../../../reducers";
import { addErrorFlash } from "../../../Flash/flashReducer";
import { AlertType } from "../types";
import MainGroup from "./MainGroup";
import Notifications from "./Notifications";
import NumberGroup from "./NumberGroup";

type FormProps = {
  alert?: AlertType;
  onSave: (values: FormValues) => void;
};

export type FormValues = {
  numberGroupId: string;
  mainGroupId: string;
  reachability: number;
  inGroup: boolean;
  daily: boolean;
  skipSundays: boolean;
  repeat: number;
  emails: { email: string; id: string }[];
};

const initialValues = (alert: AlertType | undefined): FormValues => ({
  numberGroupId: alert?.numberGroupId || "",
  mainGroupId: alert?.mainGroupId || "",
  reachability: alert?.reachability || 0,
  inGroup: !!alert?.inGroup,
  daily: !!alert?.daily,
  skipSundays: !!alert?.skipSundays,
  repeat: alert?.repeat || 0,
  emails: alert?.emails.map((email) => ({ email: email, id: nanoid() })) || [],
});

const validationSchema = Yup.object({
  numberGroupId: Yup.string().required("Bitte geben Sie eine Nummerngruppe an!"),
  mainGroupId: Yup.string().required("Bitte geben Sie einen Standort an!"),
  reachability: Yup.number().required("Bitte geben Sie eine Erreichbarkeit an!"),
  inGroup: Yup.boolean(),
  daily: Yup.boolean(),
  skipSundays: Yup.boolean(),
  repeat: Yup.number(),
  emails: Yup.array().of(
    Yup.object({
      email: Yup.string().required("Bitte geben Sie eine Email-Adresse an!"),
    }),
  ),
});

export default function RelForm({ alert, onSave }: FormProps) {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);

  async function handleSubmit(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) {
    setSubmitting(true);

    try {
      await onSave(values);
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
      setSubmitting(false);
    }
  }

  return (
    <Formik initialValues={initialValues(alert)} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(bag) => (
        <Form>
          <fieldset>
            <NumberGroup />
            <MainGroup />

            <FormGroup>
              <FormLabel htmlFor="reachability">Erreichbarkeit</FormLabel>
              <Input name="reachability" id="reachability" type="number" step={0.1} min={0} max={100} />
              <ErrorMessage name="reachability" />
            </FormGroup>

            <FormGroup>
              <Checkbox label="Erreichbarkeit in Gruppe" name="inGroup" id="inGroup" />
              <Checkbox label="Sonntags auslassen" name="skipSundays" id="skipSundays" />
              <Checkbox label="einmal täglich" name="daily" id="daily" />
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="repeat">Wiederholung vor Benachrichtigung</FormLabel>
              <Input name="repeat" id="repeat" type="number" step={1} min={0} />
              <FormText>
                Die Erreichbarkeit muss die hier angetragene Anzahl von Stunden hintereinander unterschritten werden
              </FormText>
              <ErrorMessage name="repeat" />
            </FormGroup>
          </fieldset>

          <Notifications />

          <FormActionsContainer>
            <Button type="submit">speichern</Button>
            <Button as={Link as any} variant="secondary" to={adminAlertsPath(session)}>
              abbrechen
            </Button>
          </FormActionsContainer>
        </Form>
      )}
    </Formik>
  );
}
