import Button from "react-bootstrap/Button";
import Icon from "react-icons-kit";
import { ic_details } from "react-icons-kit/md";

import { TTButtonProps } from "./types";

export default function ShowButton(props: TTButtonProps) {
  return (
    <Button variant="secondary" {...props}>
      <Icon icon={ic_details} /> {props.children || "Details"}
    </Button>
  );
}
