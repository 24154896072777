import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import apolloClient from "../../apollo";
import { getAuthorizationToken, removeAuthorizationToken, setAuthorizationToken } from "../../authorizationToken";
import type { AppThunk } from "../../store";
import type { UserType } from "../admin/users/types";
import { addErrorFlash } from "../Flash/flashReducer";
import { CUSTOMER_GET_QUERY, type CustomerDataType, LOGIN_MUTATION, REFRESH_MUTATION } from "./queries";

export type CustomerType = {
  id: string;
  name: string;
  identifier: string;
  insertedAt: string;
  updatedAt: string;
};

export type SessionState = {
  user: UserType | null;
  customer: CustomerType | null;
  checked: boolean;
  loading: boolean;
  token: string | null;
};

const initialState: SessionState = {
  user: null,
  customer: null,
  checked: false,
  loading: false,
  token: null,
};

const sessionReducer = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserType | null>) {
      state.user = action.payload;
      state.checked = true;
    },
    setCustomer(state, action: PayloadAction<CustomerType | null>) {
      state.customer = action.payload;
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setUser, setToken, setLoading, setCustomer } = sessionReducer.actions;
export default sessionReducer.reducer;

type LoginMutation = {
  login: {
    user: UserType;
    token: string;
  };
};

type RefreshMutation = {
  refresh: {
    user: UserType;
    token: string;
  };
};

export const refreshUser = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));

  const { data } = await apolloClient.mutate<RefreshMutation>({
    mutation: REFRESH_MUTATION,
    variables: { token: getAuthorizationToken() },
  });

  dispatch(setUser(data?.refresh.user || null));
  dispatch(setToken(data?.refresh.token || null));

  let customer;

  if (data?.refresh.user) {
    const { data: cData } = await apolloClient.query<CustomerDataType>({
      query: CUSTOMER_GET_QUERY,
      variables: { id: data.refresh.user.customerId },
    });

    if (!cData?.customer) {
      throw new Error("Customer is empty!");
    }

    dispatch(setCustomer(cData.customer));
    customer = cData.customer;
  }

  dispatch(setLoading(false));

  if (data?.refresh.user && customer) {
    setAuthorizationToken(data.refresh.token);
  } else {
    dispatch(setUser(null));
    dispatch(setCustomer(null));
    dispatch(addErrorFlash("Benutzername oder Passwort sind falsch!"));
  }

  return data?.refresh.user;
};

export const loginUser =
  (email: string, password: string, otp: string | null): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    console.log(otp);

    const { data } = await apolloClient.mutate<LoginMutation>({
      mutation: LOGIN_MUTATION,
      variables: { email, password, otp },
    });

    if (data?.login.user) {
      setAuthorizationToken(data.login.token);
    }

    dispatch(setUser(data?.login.user || null));
    dispatch(setToken(data?.login.token || null));

    let customer;

    if (data?.login.user) {
      const { data: cData } = await apolloClient.query<CustomerDataType>({
        query: CUSTOMER_GET_QUERY,
        variables: { id: data.login.user.customerId },
      });

      if (!cData?.customer) {
        throw new Error("Customer is empty!");
      }

      dispatch(setCustomer(cData.customer));
      customer = cData.customer;
    }

    dispatch(setLoading(false));

    if (!data?.login?.user || !customer) {
      dispatch(setUser(null));
      dispatch(setCustomer(null));
      dispatch(addErrorFlash("Benutzername oder Passwort sind falsch!"));
      return null;
    }

    return data.login?.user;
  };

export const logoutUser = (): AppThunk => async (dispatch) => {
  removeAuthorizationToken();

  dispatch(setUser(null));
  dispatch(setToken(null));
  dispatch(setCustomer(null));
};
