import {
  addDays,
  addHours,
  addMonths,
  differenceInDays,
  setHours,
  startOfDay,
  startOfHour,
  startOfMonth,
} from "date-fns";

import { ReachabilityDateType } from "./queries";

export type GroupType = "hour" | "day" | "month";

export const getGroupType = (start: Date, stop: Date): GroupType => {
  const diff = differenceInDays(stop, start);

  if (diff < 1) return "hour";
  if (diff <= 31) return "day";
  return "month";
};

export const dateFormatForTab = (start: Date, stop: Date) => {
  const groupType = getGroupType(start, stop);

  switch (groupType) {
    case "hour":
      return "HH:mm";
    case "day":
      return "dd. LLL";
    case "month":
      return "LLL yyyy";
  }
};

const getIntervalFn = (groupType: GroupType) => {
  switch (groupType) {
    case "hour":
      return addHours;
    case "day":
      return addDays;
    case "month":
      return addMonths;
  }
};

export const getCategories = (data: ReachabilityDateType[], start: Date, stop: Date) => {
  const groupType = getGroupType(start, stop);
  const intervalFn = getIntervalFn(groupType);
  const minData = data.find((v) => v.busy + v.connected + v.connectedElsewhere + v.failed !== 0);
  const maxData = data[data.length - 1];

  const minCat = minData && minData.date.getHours() < 7 ? minData.date : setHours(start, 7);
  const maxCat = maxData && maxData.date.getHours() > 21 ? maxData.date : setHours(stop, 21);

  const categories = [];
  let date = minCat;

  if (groupType === "hour") {
    date = startOfHour(date);
  } else if (groupType === "day") {
    date = startOfDay(date);
  } else if (groupType === "month") {
    date = startOfMonth(date);
  }

  while (date < maxCat) {
    categories.push(date);
    date = intervalFn(date, 1);
  }

  return categories;
};
