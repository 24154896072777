import { combineReducers } from "redux";

import alertsReducer from "./components/admin/alerts/alertsReducer";
import mainGroupsReducer from "./components/admin/main_groups/mainGroupsReducer";
import numberGroupsReducer from "./components/admin/number_groups/numberGroupsReducer";
import profilesReducer from "./components/admin/profiles/profilesReducer";
import userReducer from "./components/admin/users/userReducer";
import sessionReducer from "./components/App/sessionReducer";
import flashReducer from "./components/Flash/flashReducer";
import layoutReducer from "./components/Layout/layoutReducer";
import reachabilityReducer from "./components/Reachability/reachabilityReducer";
import type { RootState } from "./store";

const rootReducer = combineReducers({
  session: sessionReducer,
  flash: flashReducer,
  layout: layoutReducer,
  reachability: reachabilityReducer,
  users: userReducer,
  mainGroups: mainGroupsReducer,
  numberGroups: numberGroupsReducer,
  profiles: profilesReducer,
  alerts: alertsReducer,
});

export const sessionSelector = (state: RootState) => state.session;

export default rootReducer;
