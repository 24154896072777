import "./style.scss";

import React from "react";

import Spinner from "../../Spinner";

interface FetchMoreButtonProps<TData = any> {
  data: TData;
  loading: boolean;
  fetchMore: Function;
  onFetchMore: (fetchMore: Function, data: TData) => void;
}

function FetchMoreButton(props: FetchMoreButtonProps) {
  function fetchMore() {
    props.onFetchMore(props.fetchMore, props.data);
  }

  const text = props.loading ? "lade…" : "mehr laden";
  return (
    <button className="TR-fetch-more-button" onClick={fetchMore} disabled={props.loading}>
      {props.loading && <Spinner color="#000" />} {text}
    </button>
  );
}

export default React.memo(FetchMoreButton);
