const currencyFormatters: { [key: string]: Intl.NumberFormat } = {
  EUR: new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }),
};

const percentageFormatter = new Intl.NumberFormat("de-DE", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const numberFormatter = new Intl.NumberFormat("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
const intNumberFormatter = new Intl.NumberFormat("de-DE", { maximumFractionDigits: 0, minimumFractionDigits: 0 });

export const formatCurrency = (value: number, currency: string = "EUR") => {
  if (!currencyFormatters[currency]) {
    currencyFormatters[currency] = new Intl.NumberFormat("de-DE", { style: "currency", currency });
  }

  if (isNaN(value)) value = 0;

  return currencyFormatters[currency].format(value);
};

export const formatPercentage = (value: number) => {
  if (isNaN(value)) value = 0;
  if (value > 1) value /= 100.0;
  return percentageFormatter.format(value);
};

export const formatNumber = (value: number) => {
  if (isNaN(value)) value = 0;
  return numberFormatter.format(value);
};

export const formatIntNumber = (value: number) => {
  if (isNaN(value)) value = 0;
  return intNumberFormatter.format(value);
};
