import "./style.scss";

import { getIn, useFormikContext } from "formik";

export default function ErrorMessage({ name }: { name: string | string[] }) {
  const { errors, touched } = useFormikContext();
  const error = getIn(errors, name);

  if (!error || !getIn(touched, name)) {
    return null;
  }

  return <div className="TR-error-feedback">{error}</div>;
}
