export function setAuthorizationToken(token: string) {
  localStorage.setItem("reachabilityToken", token);
}

export function removeAuthorizationToken() {
  if (process.env.NODE_ENV !== "development") {
    localStorage.removeItem("reachabilityToken");
  }
}

export function getAuthorizationToken(): string | null | undefined {
  return localStorage.reachabilityToken;
}
