import { useQuery } from "@apollo/client";

import { useFormikContext } from "formik";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Select from "react-select";

import { useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../reducers";
import { notEmpty } from "../../../utils/dataUtils";
import { PROFILE_LIST_QUERY } from "../profiles/queries";
import { ProfilesDataType } from "../profiles/types";
import { FormValues } from "./RelForm";

export default function Profiles() {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { customer } = useAppSelector(sessionSelector);
  const { data } = useQuery<ProfilesDataType>(PROFILE_LIST_QUERY, {
    variables: { limit: 25, offset: 0, customerId: customer!.id },
  });

  const options = (data?.profiles || []).map((p) => ({ label: p.name, value: p.id }));
  const value = values.profiles.map((id) => options.find((opt) => opt.value === id)).filter(notEmpty);

  function handleChange(value: any) {
    setFieldValue(
      "profiles",
      (value || []).map((opt: any) => opt.value),
    );
  }

  return (
    <fieldset>
      <legend>Profile</legend>

      <FormGroup>
        <FormLabel htmlFor="profiles">Profile</FormLabel>
        <Select
          placeholder="Profile auswählen…"
          isMulti
          id="profiles"
          name="profiles"
          options={options}
          value={value}
          onChange={handleChange}
        />
      </FormGroup>
    </fieldset>
  );
}
