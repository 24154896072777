import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    role
    active
    otp
    firstname
    lastname
    insertedAt
    updatedAt

    profiles {
      id
      name
    }
  }
`;

export const USER_LIST_QUERY = gql`
  query ($customerId: ID!, $limit: Int!, $offset: Int!, $search: String) {
    users(customerId: $customerId, limit: $limit, offset: $offset, search: $search) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_QUERY = gql`
  query ($customerId: ID!, $id: ID!) {
    user(customerId: $customerId, id: $id) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID, $user: UserInput!) {
    mutateUser(customerId: $customerId, id: $id, user: $user) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_DELETE_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID!) {
    deleteUser(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const DISABLE_OTP_FOR_USER_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID!) {
    disableOtpForUser(customerId: $customerId, id: $id) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;
