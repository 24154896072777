import { useQuery } from "@apollo/client";

import { useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import { FormValues } from ".";
import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { notEmpty } from "../../utils/dataUtils";
import { MyNumberGroupsData, NUMBER_GROUPS_QUERY } from "../Reachability/queries";

export default function NumberGroups() {
  const session = useAppSelector(sessionSelector);
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { data } = useQuery<MyNumberGroupsData>(NUMBER_GROUPS_QUERY, {
    variables: { customerId: session.customer!.id },
  });

  const options = (data?.myNumberGroups || [])
    .map((ng) => ({
      value: ng.id,
      label: ng.name,
      numberGroup: ng,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const value = values.numberGroups
    .map((id) => options.find(({ value }) => value === id))
    .filter(notEmpty)
    .sort((a, b) => a!.label.localeCompare(b!.label));

  function handleChange(value: any) {
    setFieldValue(
      "numberGroups",
      (value || []).map((v: any) => v.value),
    );
  }

  return (
    <Form.Group>
      <Form.Label>Nummerngruppen</Form.Label>
      <Select placeholder="Nummergruppen auswählen…" isMulti options={options} value={value} onChange={handleChange} />
    </Form.Group>
  );
}
