import { useQuery } from "@apollo/client";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Table from "react-bootstrap/Table";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { formatPercentage } from "../../utils/numberUtils";
import { MainGroupType } from "../admin/main_groups/types";
import { NumberGroupType } from "../admin/number_groups/types";
import {
  MAIN_GROUPS_QUERY,
  MyMainGroupsData,
  MyNumberGroupsData,
  NUMBER_GROUPS_QUERY,
  ReachabilityRowType,
} from "./queries";
import { setGroups } from "./reachabilityReducer";
import { activeClass, classname, collectGroups } from "./utils";

type ReachabilityTableProps = {
  data: ReachabilityRowType[];
};

const reachabilityForGroup = (data: ReachabilityRowType[], numberGroup: NumberGroupType, mainGroup: MainGroupType) => {
  const ret = {
    connected: 0,
    connectedElsewhere: 0,
    busy: 0,
    failed: 0,
    reachabilityInGroup: 0,
    reachabilityElsewhere: 0,
  };

  const relevantData = data.filter((row) => row.mainGroupId === mainGroup.id && row.numberGroupId === numberGroup.id);

  if (relevantData.length === 0) return null;

  relevantData.forEach((row) => {
    ret.connected += row.connected;
    ret.connectedElsewhere += row.connectedElsewhere;
    ret.busy += row.busy;
    ret.failed += row.failed;
  });

  ret.reachabilityInGroup = (ret.connected * 100) / (ret.connected + ret.connectedElsewhere + ret.busy + ret.failed);
  ret.reachabilityElsewhere =
    ((ret.connected + ret.connectedElsewhere) * 100) / (ret.connected + ret.connectedElsewhere + ret.busy + ret.failed);

  return ret;
};

export default function ReachabilityTable({ data }: ReachabilityTableProps) {
  const { numberGroup, mainGroup } = useAppSelector((state) => state.reachability);
  const session = useAppSelector(sessionSelector);
  const dispatch = useAppDispatch();

  const { data: numberGroupsData } = useQuery<MyNumberGroupsData>(NUMBER_GROUPS_QUERY, {
    variables: { customerId: session.customer!.id },
  });
  const { data: mainGroupsData } = useQuery<MyMainGroupsData>(MAIN_GROUPS_QUERY, {
    variables: { customerId: session.customer!.id },
  });

  const { numberGroups, mainGroups } = collectGroups(
    data,
    numberGroupsData?.myNumberGroups || [],
    mainGroupsData?.myMainGroups || [],
  );

  function chooseGroups(newNumberGroup: NumberGroupType, newMainGroup: MainGroupType) {
    if (numberGroup?.id === newNumberGroup.id && mainGroup?.id === newMainGroup.id) {
      dispatch(setGroups({}));
    } else {
      dispatch(setGroups({ numberGroup: newNumberGroup, mainGroup: newMainGroup }));
    }
  }

  return (
    <Table responsive className="TR-reachability-table">
      <thead>
        <tr>
          <th>Nummerngruppe</th>
          {mainGroups.map((mg) => (
            <th key={mg.id}>{mg.name}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {numberGroups.map((rowNumberGroup) => (
          <tr key={rowNumberGroup.id}>
            <th scope="row">{rowNumberGroup.name}</th>

            {mainGroups.map((rowMainGroup) => {
              const row = reachabilityForGroup(data, rowNumberGroup, rowMainGroup);
              return (
                <td key={rowMainGroup.id} className={activeClass(rowNumberGroup, rowMainGroup, numberGroup, mainGroup)}>
                  {row && (
                    <>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        overlay={(props) => tooltip(rowNumberGroup, rowMainGroup, true, props)}
                      >
                        <button
                          onClick={() => chooseGroups(rowNumberGroup, rowMainGroup)}
                          className={classname("table-in-group", row.reachabilityInGroup, rowNumberGroup)}
                        >
                          {formatPercentage(row.reachabilityInGroup)}
                        </button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        trigger={["hover", "focus", "click"]}
                        overlay={(props) => tooltip(rowNumberGroup, rowMainGroup, false, props)}
                      >
                        <button
                          onClick={() => chooseGroups(rowNumberGroup, rowMainGroup)}
                          className={classname("table-external", row.reachabilityElsewhere, rowNumberGroup)}
                        >
                          {formatPercentage(row.reachabilityElsewhere)}
                        </button>
                      </OverlayTrigger>
                    </>
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const tooltip = (numberGroup: NumberGroupType, mainGroup: MainGroupType, inGroup: boolean, restProps: any) => {
  return (
    <Popover id={`tooltip-${numberGroup.id}-${mainGroup.id}`} className="TR-reachability-table-popover" {...restProps}>
      <Popover.Header as="h3">
        Erreichbarkeit {inGroup ? "in Gruppe" : "global"} (Gruppe {numberGroup.name} / {mainGroup.name})
      </Popover.Header>
      <Popover.Body>
        <p>Nummern:</p>

        <ul>
          {numberGroup.numbers
            .filter((num) => num.mainGroupId === mainGroup.id)
            .map((num) => (
              <li key={num.id}>{num.number}</li>
            ))}
        </ul>
      </Popover.Body>
    </Popover>
  );
};
