import React from "react";

import Form from "react-bootstrap/Form";

type PropsType = {
  value?: string;
  onClick?: (ev: React.MouseEvent<HTMLInputElement>) => void;
};

export default function DatePickerInput({ value, onClick }: PropsType) {
  return <Form.Control onClick={onClick} value={value} readOnly />;
}
