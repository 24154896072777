import { MainGroupType } from "../admin/main_groups/types";
import { NumberGroupType } from "../admin/number_groups/types";
import { DashboardConfigType } from "../DashboardConfig/queries";
import { ReachabilityRowType } from "./queries";

export const collectGroups = (
  data: ReachabilityRowType[],
  numberGroups: NumberGroupType[],
  mainGroups: MainGroupType[],
) => {
  const mainGroupIds: { [key: string]: MainGroupType | undefined } = {};
  const numberGroupIds: { [key: string]: NumberGroupType | undefined } = {};

  data.forEach((row) => {
    if (!numberGroupIds[row.numberGroupId])
      numberGroupIds[row.numberGroupId] = numberGroups.find((g) => g.id === row.numberGroupId);
    if (!mainGroupIds[row.mainGroupId])
      mainGroupIds[row.mainGroupId] = mainGroups.find((g) => g.id === row.mainGroupId);
  });

  return {
    mainGroups: Object.values(mainGroupIds)
      .filter((g) => !!g)
      .sort((a, b) => a!.name.localeCompare(b!.name)),
    numberGroups: Object.values(numberGroupIds)
      .filter((g) => !!g)
      .sort((a, b) => a!.name.localeCompare(b!.name)),
  } as { numberGroups: NumberGroupType[]; mainGroups: MainGroupType[] };
};

export const classname = (base: string, val: number | undefined, group: NumberGroupType) => {
  if (typeof val === "undefined") return `TR-reachability-${base}`;

  let cls = "";

  if (val >= group.limitWarning) cls = "good";
  else if (val >= group.limitBad) cls = "warning";
  else cls = "bad";

  return `TR-reachability-${base} TR-reachability-${cls}`;
};

export const activeClass = (
  rowNumberGroup: NumberGroupType,
  rowMainGroup: MainGroupType,
  numberGroup: NumberGroupType | undefined,
  mainGroup: MainGroupType | undefined,
) => {
  if (rowNumberGroup.id === numberGroup?.id && rowMainGroup.id === mainGroup?.id) return "active";
  return undefined;
};

export const shouldShowListTable = (
  numberGroup: NumberGroupType | undefined,
  mainGroup: MainGroupType | undefined,
  selectedNumberGroups: NumberGroupType[],
  useDashboard: boolean,
  config: DashboardConfigType | undefined,
) => {
  return (numberGroup && mainGroup) || (selectedNumberGroups.length === 1 && (!useDashboard || !config?.id));
};
