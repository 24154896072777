import { endOfDay, endOfHour, endOfMonth, isSameDay, isSameHour, isSameMonth, parseJSON } from "date-fns";

import { MainGroupType } from "../admin/main_groups/types";
import { NumberGroupType } from "../admin/number_groups/types";
import { GroupType } from "./categories";
import { ReachabilityDateType, ReachabilityRowType } from "./queries";

export const aggregateData = (
  data: ReachabilityRowType[],
  groupType: GroupType,
  numberGroup: NumberGroupType | undefined,
  mainGroup: MainGroupType | undefined,
  ignoreOutOfWorking: boolean = true,
) => {
  const grouped: { [key: string]: ReachabilityDateType } = {};

  data.forEach((row) => {
    const date = parseJSON(row.date);

    if (ignoreOutOfWorking && groupType === "hour" && (date.getHours() < 6 || date.getHours() > 21)) return;
    if (numberGroup && numberGroup.id !== row.numberGroupId) return;
    if (mainGroup && mainGroup.id !== row.mainGroupId) return;

    if (!grouped[row.date])
      grouped[row.date] = {
        connected: 0,
        connectedElsewhere: 0,
        failed: 0,
        busy: 0,
        date,
        reachabilityElsewhere: 0,
        reachabilityInGroup: 0,
      };

    grouped[row.date].connected += row.connected;
    grouped[row.date].connectedElsewhere += row.connectedElsewhere;
    grouped[row.date].busy += row.busy;
    grouped[row.date].failed += row.failed;
  });

  const rows = Object.values(grouped).sort((a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  });

  return rows.map((v) => {
    v.reachabilityInGroup = (v.connected * 100) / (v.connected + v.connectedElsewhere + v.busy + v.failed);
    v.reachabilityElsewhere =
      ((v.connected + v.connectedElsewhere) * 100) / (v.connected + v.connectedElsewhere + v.busy + v.failed);
    return v;
  });
};

export const compareFnForType = (type: GroupType) => {
  switch (type) {
    case "hour":
      return isSameHour;
    case "day":
      return isSameDay;
    case "month":
      return isSameMonth;
  }
};

export const endOfFnForType = (type: GroupType) => {
  switch (type) {
    case "hour":
      return endOfHour;
    case "day":
      return endOfDay;
    case "month":
      return endOfMonth;
  }
};

export const getDataByCategories = (data: ReachabilityDateType[], categories: Date[], type: GroupType) => {
  const categorizedData: (ReachabilityDateType | null)[] = [];
  const compareFun = compareFnForType(type);

  categories.forEach((date) => {
    const datum = data.find((row) => compareFun(row.date, date));
    if (datum) {
      categorizedData.push(datum);
    } else {
      categorizedData.push(null);
    }
  });

  return categorizedData;
};
