import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import AdminInterface from "../admin";
import ChangePasswordInterface from "../ChangePassword";
import DashboardConfigInterface from "../DashboardConfig";
import Layout from "../Layout";
import ReachabilityInterface from "../Reachability";

export default function AuthedRoutes() {
  const { customer } = useAppSelector(sessionSelector);
  const { pathname } = useLocation();

  if (!customer) {
    return null;
  }

  const customerPath = `/${customer.identifier}`;
  if (!pathname.startsWith(customerPath)) {
    return <Navigate to={customerPath} />;
  }

  return (
    <Layout>
      <Routes>
        <Route path="/:customer/admin/*" element={<AdminInterface />} />
        <Route path="/:customer/config" element={<DashboardConfigInterface />} />
        <Route path="/:customer/change-password" element={<ChangePasswordInterface />} />
        <Route path="/:customer/*" element={<ReachabilityInterface />} />
      </Routes>
    </Layout>
  );
}
