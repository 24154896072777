import React from "react";

import { getIn, useFormikContext } from "formik";
import FormControl, { FormControlProps } from "react-bootstrap/FormControl";

import { fieldInvalid, fieldValid } from "./Input";

type FormInputProps = {
  fast?: boolean;
  validate?: boolean;
  name: string;
  children?: React.ReactNode;
} & FormControlProps;

export default function Select(props: FormInputProps) {
  const context = useFormikContext();

  const isValid = fieldValid(context.errors, context.touched, props.name);
  const isInvalid = fieldInvalid(context.errors, context.touched, props.name);
  const value = getIn(context.values, props.name);

  return (
    <FormControl
      value={value}
      as="select"
      onChange={context.handleChange}
      onBlur={context.handleBlur}
      isValid={isValid}
      isInvalid={isInvalid}
      {...props}
    >
      {props.children}
    </FormControl>
  );
}
