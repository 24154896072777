import { gql } from "@apollo/client";

import { MAIN_GROUP_FRAGMENT } from "../admin/main_groups/queries";
import { MainGroupType } from "../admin/main_groups/types";
import { NumberGroupType } from "../admin/number_groups/types";

export type DashboardConfigType = {
  id: string;
  userId: string;

  numberGroups: NumberGroupType[];
  mainGroups: MainGroupType[];
};

export type DashboardConfigData = {
  dashboardConfig: DashboardConfigType;
};

export const GET_CONFIG_QUERY = gql`
  query ($customerId: ID!) {
    dashboardConfig(customerId: $customerId) {
      id

      numberGroups {
        id
        name
      }

      mainGroups {
        id
        name
      }
    }
  }
`;

export const UPDATE_CONFIG_MUTATION = gql`
  mutation ($customerId: ID!, $dashboardConfig: DashboardConfigInput!) {
    mutateDashboardConfig(customerId: $customerId, dashboardConfig: $dashboardConfig) {
      id

      numberGroups {
        id
        name
      }

      mainGroups {
        id
        name
      }
    }
  }
`;

export const MAIN_GROUPS_QUERY = gql`
  query ($customerId: ID!) {
    myMainGroups(customerId: $customerId) {
      ...MainGroupFragment

      numbers {
        id
        numberGroupId
      }
    }
  }
  ${MAIN_GROUP_FRAGMENT}
`;
