import { gql } from "@apollo/client";

export const ALERT_FRAGMENT = gql`
  fragment AlertFragment on Alert {
    id
    reachability

    daily
    inGroup
    skipSundays

    repeat
    repeated

    emails

    insertedAt
    updatedAt

    mainGroupId
    numberGroupId
  }
`;

export const ALERT_LIST_QUERY = gql`
  query ($customerId: ID!, $limit: Int, $offset: Int, $search: String) {
    alerts(customerId: $customerId, limit: $limit, offset: $offset, search: $search) {
      ...AlertFragment

      mainGroup {
        id
        name
      }

      numberGroup {
        id
        name
      }
    }
  }
  ${ALERT_FRAGMENT}
`;

export const ALERT_QUERY = gql`
  query ($customerId: ID!, $id: ID!) {
    alert(customerId: $customerId, id: $id) {
      ...AlertFragment
    }
  }
  ${ALERT_FRAGMENT}
`;

export const ALERT_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID, $alert: AlertInput!) {
    mutateAlert(customerId: $customerId, id: $id, alert: $alert) {
      ...AlertFragment
    }
  }
  ${ALERT_FRAGMENT}
`;

export const ALERT_DELETE_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID!) {
    deleteAlert(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const MAIN_GROUP_LIST = gql`
  query ($customerId: ID!, $search: String) {
    mainGroups(customerId: $customerId, search: $search) {
      id
      name
    }
  }
`;

export const NUMBER_GROUP_LIST = gql`
  query ($customerId: ID!, $search: String) {
    numberGroups(customerId: $customerId, search: $search) {
      id
      name
    }
  }
`;
