import React from "react";

import { useMutation, useQuery } from "@apollo/client";

import { useNavigate, useParams } from "react-router-dom";

import Loading from "../../../containers/Loading";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import { adminAlertsPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addSuccessFlash } from "../../Flash/flashReducer";
import { ALERT_MUTATION, ALERT_QUERY } from "./queries";
import RelForm, { FormValues } from "./RelForm";
import { AlertDataType } from "./types";

export default function Edit() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { id } = useParams<{ id: string }>();
  const { data } = useQuery<AlertDataType>(ALERT_QUERY, { variables: { id, customerId: session.customer!.id } });
  const [alertMutation] = useMutation(ALERT_MUTATION);
  const navigate = useNavigate();

  useTitle(`Alarm bearbeiten`);

  async function onSave(values: FormValues) {
    const emails = values.emails.map(({ email }) => email);

    await alertMutation({
      variables: {
        id: data!.alert.id,
        customerId: session.customer!.id,
        alert: { ...values, emails },
      },
    });

    dispatch(addSuccessFlash("Der Alarm wurde erfolgreich editiert!"));
    navigate(adminAlertsPath(session));
  }

  if (!data?.alert) return <Loading stretch />;

  return (
    <React.Fragment>
      <h2>Alarm bearbeiten</h2>

      <RelForm alert={data.alert} onSave={onSave} />
    </React.Fragment>
  );
}
