import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AlertsReducerState = {
  searchTerm: undefined | string;
};

const initialState: AlertsReducerState = {
  searchTerm: undefined,
};

const alertsReducer = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload === "" ? undefined : action.payload;
    },
  },
});

export const { setSearchTerm } = alertsReducer.actions;
export default alertsReducer.reducer;
