import { gql } from "@apollo/client";

export const NUMBER_GROUP_FRAGMENT = gql`
  fragment NumberGroupFragment on NumberGroup {
    id
    name

    limitWarning
    limitBad
    preaggregated
    insertedAt
    updatedAt

    numbers {
      id
      number
      mainGroupId
    }
  }
`;

export const NUMBER_GROUP_LIST_QUERY = gql`
  query ($customerId: ID!, $limit: Int!, $offset: Int!, $search: String) {
    numberGroups(customerId: $customerId, limit: $limit, offset: $offset, search: $search) {
      ...NumberGroupFragment
    }
  }
  ${NUMBER_GROUP_FRAGMENT}
`;

export const NUMBER_GROUP_QUERY = gql`
  query ($customerId: ID!, $id: ID!) {
    numberGroup(customerId: $customerId, id: $id) {
      ...NumberGroupFragment
    }
  }
  ${NUMBER_GROUP_FRAGMENT}
`;

export const NUMBER_GROUP_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID, $numberGroup: NumberGroupInput!) {
    mutateNumberGroup(customerId: $customerId, id: $id, numberGroup: $numberGroup) {
      ...NumberGroupFragment
    }
  }
  ${NUMBER_GROUP_FRAGMENT}
`;

export const NUMBER_GROUP_DELETE_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID!) {
    deleteNumberGroup(customerId: $customerId, id: $id) {
      id
    }
  }
`;
