import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../../store";

export type FlashMessageType = "success" | "notice" | "error";
export type FlashMessage = {
  id: string;
  type: FlashMessageType;
  text: string;
};

const flashReducer = createSlice({
  name: "flash",
  initialState: [] as FlashMessage[],
  reducers: {
    addFlashMessage(state, action: PayloadAction<FlashMessage>) {
      state.push(action.payload);
    },
    removeFlashMessage(state, action: PayloadAction<string>) {
      return state.filter((msg) => msg.id !== action.payload);
    },
  },
});

export const { addFlashMessage, removeFlashMessage } = flashReducer.actions;

export const addFlash =
  (text: string, timeout: number, type: FlashMessageType): AppThunk =>
  (dispatch) => {
    const msg: FlashMessage = { id: nanoid(), text, type };

    if (timeout) {
      window.setTimeout(() => {
        dispatch(removeFlashMessage(msg.id));
      }, timeout * 1000);
    }

    dispatch(addFlashMessage(msg));
  };

export const addNoticeFlash = (text: string, timeout: number = 10) => addFlash(text, timeout, "notice");
export const addSuccessFlash = (text: string, timeout: number = 10) => addFlash(text, timeout, "success");
export const addErrorFlash = (text: string, timeout: number = 0) => addFlash(text, timeout, "error");

export default flashReducer.reducer;
