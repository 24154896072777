import { useEffect } from "react";

import { useAppDispatch } from ".";
import { addSubnav, removeSubnav } from "../components/Layout/layoutReducer";

export default function useSubnav(nav: string, props = {}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addSubnav({ subSubNav: nav, props }));

    return () => {
      dispatch(removeSubnav());
    };
  }, [dispatch, nav, props]);
}
