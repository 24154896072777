import { useQuery } from "@apollo/client";

import { useFormikContext } from "formik";
import Select from "react-select";

import { FormValues } from ".";
import { useAppSelector } from "../../../../hooks";
import { sessionSelector } from "../../../../reducers";
import { OptionType, TROptionValueType } from "../../../UserSelector";
import { MainGroupsDataType } from "../../main_groups/types";
import { MAIN_GROUPS_QUERY } from "../queries";

export default function MainGroups() {
  const session = useAppSelector(sessionSelector);
  const context = useFormikContext<FormValues>();
  const { data } = useQuery<MainGroupsDataType>(MAIN_GROUPS_QUERY, { variables: { customerId: session.customer!.id } });

  const options = (data?.mainGroups || []).map((mg) => ({
    label: mg.name,
    value: mg.id,
  }));

  const value = context.values.mainGroups
    .map((id) => options.find(({ value }) => value === id))
    .filter((v) => !!v) as OptionType[];

  function handleChange(value: TROptionValueType<true>) {
    context.setFieldValue(
      "mainGroups",
      (value || []).map((v: OptionType) => v.value),
    );
  }

  return (
    <fieldset>
      <legend>Standorte</legend>

      <Select options={options} value={value} onChange={handleChange} isMulti placeholder="Standorte wählen…" />
    </fieldset>
  );
}
