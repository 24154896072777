import { useMutation, useQuery } from "@apollo/client";

import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import ActionColumn from "../../../containers/ActionColumn";
import DeleteButton from "../../../containers/buttons/DeleteButton";
import EditButton from "../../../containers/buttons/EditButton";
import Loading from "../../../containers/Loading";
import NoDataRow from "../../../containers/NoDataRow";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { editAdminNumberGroupPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { relativeTime } from "../../../utils/dateUtils";
import FetchMore from "../../FetchMore";
import { addErrorFlash, addSuccessFlash } from "../../Flash/flashReducer";
import { NUMBER_GROUP_DELETE_MUTATION, NUMBER_GROUP_LIST_QUERY } from "./queries";
import SearchForm from "./SearchForm";
import { NumberGroupsDataType, NumberGroupType } from "./types";

export default function List() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { searchTerm: search } = useAppSelector((state) => state.numberGroups);

  const { data, fetchMore, loading } = useQuery<NumberGroupsDataType>(NUMBER_GROUP_LIST_QUERY, {
    variables: { limit: 25, offset: 0, customerId: session.customer!.id, search },
  });
  const [deleteMainGroupMutation] = useMutation(NUMBER_GROUP_DELETE_MUTATION, {
    update(cache, { data: { deleteNumberGroup } }) {
      const numberGroups = cache.readQuery<NumberGroupsDataType>({
        query: NUMBER_GROUP_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
      })!.numberGroups;

      cache.writeQuery({
        query: NUMBER_GROUP_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
        data: { numberGroups: numberGroups.filter((ng) => ng.id !== deleteNumberGroup.id) },
      });
    },
  });

  async function deleteNumberGroup(numberGroup: NumberGroupType) {
    try {
      await deleteMainGroupMutation({ variables: { id: numberGroup.id, customerId: session.customer!.id } });
      dispatch(addSuccessFlash("Die Nummerngruppe wurde erfolgreich gelöscht!"));
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
    }
  }

  return (
    <>
      {!data?.numberGroups && <Loading fullscreen backdrop />}

      <SearchForm />

      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>erstellt</th>
            <th>bearbeitet</th>
            <th />
          </tr>
        </thead>

        <tbody>
          <NoDataRow data={data?.numberGroups} cols={4} />

          {data?.numberGroups.map((numberGroup) => (
            <tr key={numberGroup.id}>
              <td>{numberGroup.name}</td>
              <td>{relativeTime(numberGroup.insertedAt)}</td>
              <td>{relativeTime(numberGroup.updatedAt)}</td>

              <ActionColumn>
                <EditButton size="sm" as={Link as any} to={editAdminNumberGroupPath(session, numberGroup)} />
                <DeleteButton size="sm" onClick={() => deleteNumberGroup(numberGroup)} />
              </ActionColumn>
            </tr>
          ))}
        </tbody>
      </Table>

      <FetchMore fetchMore={fetchMore} data={data} loading={loading} dataKey="numberGroups" />
    </>
  );
}
