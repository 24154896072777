import React from "react";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link, useLocation } from "react-router-dom";

import NewButton from "../../../containers/buttons/NewButton";
import { useAppSelector } from "../../../hooks";
import { newAdminMainGroupPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { isActive } from "../../Layout/utils";
import Linklist from "../Linklist";

function Subnav() {
  const { pathname } = useLocation();
  const session = useAppSelector(sessionSelector);

  return (
    <React.Fragment>
      <ButtonGroup>
        <NewButton
          as={Link as any}
          to={newAdminMainGroupPath(session)}
          variant="secondary"
          size="sm"
          active={isActive(pathname, "/admin/maingroups/new")}
        >
          neuer Standort
        </NewButton>
      </ButtonGroup>

      <Linklist />
    </React.Fragment>
  );
}

export default React.memo(Subnav);
