import { nanoid } from "@reduxjs/toolkit";

import { useFormikContext } from "formik";

import { FormValues } from ".";
import DeleteButton from "../../../../containers/buttons/DeleteButton";
import NewButton from "../../../../containers/buttons/NewButton";
import ErrorMessage from "../../../../containers/Forms/ErrorMessage";
import Input from "../../../../containers/Forms/Input";

export default function Notifications() {
  const context = useFormikContext<FormValues>();

  function addEmail() {
    context.setFieldValue("emails", [...context.values.emails, { email: "", id: nanoid() }], false);
  }

  function removeEmail(id: string) {
    context.setFieldValue(
      "emails",
      context.values.emails.filter((email) => email.id !== id),
    );
  }

  return (
    <fieldset>
      <legend>Benachrichtigungen</legend>

      <ul className="TR-alerts-email-list">
        {context.values.emails.map((email, idx) => (
          <li key={email.id}>
            <div>
              <Input type="email" name={`emails.${idx}.email`} />
              <ErrorMessage name={`emails.${idx}.email`} />
            </div>
            <DeleteButton onClick={() => removeEmail(email.id)} />
          </li>
        ))}
      </ul>

      <NewButton onClick={addEmail} size="sm">
        Email-Adresse hinzufügen
      </NewButton>
    </fieldset>
  );
}
