import "./style.scss";

import React from "react";

import ButtonGroup from "react-bootstrap/ButtonGroup";

export default function ActionColumn({ children }: { children: React.ReactNode }) {
  return (
    <td className="TR-action-table-column">
      <ButtonGroup>{children}</ButtonGroup>
    </td>
  );
}
