import "./style.scss";

import { nanoid } from "@reduxjs/toolkit";

import { Form, Formik, FormikHelpers } from "formik";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import FormActionsContainer from "../../../../containers/FormActionsContainer";
import ErrorMessage from "../../../../containers/Forms/ErrorMessage";
import Input from "../../../../containers/Forms/Input";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { adminNumberGroupsPath } from "../../../../paths";
import { sessionSelector } from "../../../../reducers";
import { addErrorFlash } from "../../../Flash/flashReducer";
import { NumberGroupType } from "../types";
import Numbers from "./Numbers";

type FormProps = {
  numberGroup?: NumberGroupType;
  onSave: (values: FormValues) => void;
};

export type NumberGroupNumberValues = {
  mainGroupId: string;
  number: string;
  id?: string;
  key: string;
};

export type FormValues = {
  name: string;
  numbers: NumberGroupNumberValues[];
  limitWarning: number;
  limitBad: number;
};

const initialValues = (numberGroup: NumberGroupType | undefined): FormValues => ({
  name: numberGroup?.name || "",
  numbers:
    numberGroup?.numbers?.map((number) => ({
      id: number.id,
      number: number.number,
      mainGroupId: number.mainGroupId,
      key: nanoid(),
    })) || [],
  limitWarning: numberGroup?.limitWarning || 60,
  limitBad: numberGroup?.limitBad || 80,
});

const validationSchema = Yup.object({
  name: Yup.string().required("Bitte geben Sie einen Namen an!"),
  limitWarning: Yup.number().required("Bitte geben Sie einen Grenzwert „Warnung“ an!"),
  limitBad: Yup.number().required("Bitte geben Sie einen Grenzwert „Schlecht“ an!"),
  numbers: Yup.array().of(
    Yup.object({
      number: Yup.string().required("Bitte geben Sie eine Nummer an!"),
      mainGroupId: Yup.string().required("Bitte geben Sie einen Standort an!"),
    }),
  ),
});

export default function RelForm({ numberGroup, onSave }: FormProps) {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);

  async function handleSubmit(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) {
    setSubmitting(true);

    try {
      await onSave(values);
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
      setSubmitting(false);
    }
  }

  return (
    <Formik initialValues={initialValues(numberGroup)} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(bag) => (
        <Form>
          <fieldset>
            <legend>Gruppen-Informationen</legend>

            <FormGroup>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input name="name" id="name" />
              <ErrorMessage name="name" />
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="limitWarning">Grenzwert „Warnung“</FormLabel>
              <Input name="limitWarning" id="limitWarning" type="number" step={1} min={1} max={100} />
              <ErrorMessage name="limitWarning" />
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="limitBad">Grenzwert „Schlecht“</FormLabel>
              <Input name="limitBad" id="limitBad" type="number" step={1} min={1} max={100} />
              <ErrorMessage name="limitBad" />
            </FormGroup>
          </fieldset>

          <Numbers />

          <FormActionsContainer>
            <Button type="submit">speichern</Button>
            <Button as={Link as any} variant="secondary" to={adminNumberGroupsPath(session)}>
              abbrechen
            </Button>
          </FormActionsContainer>
        </Form>
      )}
    </Formik>
  );
}
