import { useState } from "react";

import { compareAsc, format } from "date-fns";
import Table from "react-bootstrap/Table";

import ActionColumn from "../../containers/ActionColumn";
import ShowButton from "../../containers/buttons/ShowButton";
import { useAppSelector } from "../../hooks";
import { formatPercentage } from "../../utils/numberUtils";
import { GroupType } from "./categories";
import { compareFnForType } from "./data";
import InfoModal from "./InfoModal";
import { ReachabilityDateType } from "./queries";
import { classname } from "./utils";

type ReachabilityListTableProps = {
  data: ReachabilityDateType[];
  dateFormat: string;
  groupType: GroupType;
};

export default function ReachabilityListTable({ dateFormat, data, groupType }: ReachabilityListTableProps) {
  const [showInfos, setShowInfos] = useState<{ show: boolean; date: Date | null }>({
    show: false,
    date: null,
  });
  const { numberGroup, mainGroup, selectedNumberGroups, selectedMainGroups } = useAppSelector(
    (state) => state.reachability,
  );
  const compareFn = compareFnForType(groupType);

  const group = numberGroup || selectedNumberGroups[0];
  const mGroup = mainGroup || selectedMainGroups[0];

  const sum = data.reduce(
    (acc, v) => {
      acc.connected += v.connected;
      acc.connectedElsewhere += v.connectedElsewhere;
      acc.busy += v.busy;
      acc.failed += v.failed;

      return acc;
    },
    { connected: 0, connectedElsewhere: 0, busy: 0, failed: 0 },
  );

  const reachabilityInGroup = sum.connected / (sum.connected + sum.connectedElsewhere + sum.busy + sum.failed) || 1;
  const reachabilityExtern =
    (sum.connected + sum.connectedElsewhere) / (sum.connected + sum.connectedElsewhere + sum.busy + sum.failed) || 1;

  const categories = data.map((row) => row.date).sort(compareAsc);

  return (
    <>
      <Table className="TR-reachability-list-table">
        <thead>
          <tr>
            <th></th>
            <th className="text-right">Calls</th>
            <th className="text-right">angenommen</th>
            <th className="text-right">abgebrochen</th>
            <th className="text-right">besetzt</th>
            <th className="text-right">Gruppe</th>
            <th className="text-right">Überlauf</th>
            <th className="text-right">Gruppe erreicht</th>
            <th className="text-right">global erreicht</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {categories.map((cat) => {
            const date = format(cat, dateFormat);
            const row = data.find((row) => compareFn(row.date, cat)) || {
              connected: 0,
              connectedElsewhere: 0,
              busy: 0,
              failed: 0,
              date: cat,
              reachabilityInGroup: 0,
              reachabilityElsewhere: 0,
            };

            if (row.connected + row.connectedElsewhere + row.busy + row.failed === 0) return null;

            return (
              <tr key={date}>
                <th scope="row">{date}</th>
                <td className="text-right">{row.connected + row.connectedElsewhere + row.busy + row.failed}</td>
                <td className="text-right">{row.connected + row.connectedElsewhere}</td>
                <td className="text-right">{row.failed}</td>
                <td className="text-right">{row.busy}</td>
                <td className="text-right">{row.connected}</td>
                <td className="text-right">{row.connectedElsewhere}</td>
                <td className={`text-right ${classname("in-group", row.reachabilityInGroup, group)}`}>
                  {formatPercentage(row.reachabilityInGroup)}
                </td>
                <td className={`text-right ${classname("extern", row.reachabilityElsewhere, group)}`}>
                  {formatPercentage(row.reachabilityElsewhere)}
                </td>
                <ActionColumn>
                  <ShowButton
                    size="sm"
                    onClick={() =>
                      setShowInfos({
                        show: true,
                        date: cat,
                      })
                    }
                  >
                    Details
                  </ShowButton>
                </ActionColumn>
              </tr>
            );
          })}
        </tbody>

        <tfoot>
          <tr>
            <th scope="row">Summe</th>
            <th className="text-right">{sum.connected + sum.connectedElsewhere + sum.busy + sum.failed}</th>
            <th className="text-right">{sum.connected + sum.connectedElsewhere}</th>
            <th className="text-right">{sum.failed}</th>
            <th className="text-right">{sum.busy}</th>
            <th className="text-right">{sum.connected}</th>
            <th className="text-right">{sum.connectedElsewhere}</th>
            <th className={`text-right ${classname("extern", reachabilityInGroup * 100.0, group)}`}>
              {formatPercentage(reachabilityInGroup)}
            </th>
            <th className={`text-right ${classname("extern", reachabilityExtern * 100.0, group)}`}>
              {formatPercentage(reachabilityExtern)}
            </th>
            <th />
          </tr>
        </tfoot>
      </Table>

      {showInfos.show && group && mGroup && showInfos.date && (
        <InfoModal
          onClose={() => setShowInfos({ show: false, date: null })}
          numberGroup={group}
          mainGroup={mGroup}
          date={showInfos.date}
        />
      )}
    </>
  );
}
