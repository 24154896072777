import { gql } from "@apollo/client";

export const MAIN_GROUP_FRAGMENT = gql`
  fragment MainGroupFragment on MainGroup {
    id
    name
    insertedAt
    updatedAt
  }
`;

export const MAIN_GROUP_LIST_QUERY = gql`
  query ($customerId: ID!, $limit: Int, $offset: Int, $search: String) {
    mainGroups(customerId: $customerId, limit: $limit, offset: $offset, search: $search) {
      ...MainGroupFragment
    }
  }
  ${MAIN_GROUP_FRAGMENT}
`;

export const MAIN_GROUP_QUERY = gql`
  query ($customerId: ID!, $id: ID!) {
    mainGroup(customerId: $customerId, id: $id) {
      ...MainGroupFragment
    }
  }
  ${MAIN_GROUP_FRAGMENT}
`;

export const MAIN_GROUP_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID, $mainGroup: MainGroupInput!) {
    mutateMainGroup(customerId: $customerId, id: $id, mainGroup: $mainGroup) {
      ...MainGroupFragment
    }
  }
  ${MAIN_GROUP_FRAGMENT}
`;

export const MAIN_GROUP_DELETE_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID!) {
    deleteMainGroup(customerId: $customerId, id: $id) {
      id
    }
  }
`;
