import { gql } from "@apollo/client";

export const PROFILE_FRAGMENT = gql`
  fragment ProfileFragment on Profile {
    id
    name

    users {
      id
      email
      firstname
      lastname
    }

    numberGroups {
      id
      name
    }

    mainGroups {
      id
      name
    }

    insertedAt
    updatedAt
  }
`;

export const PROFILE_LIST_QUERY = gql`
  query ($customerId: ID!, $limit: Int, $offset: Int, $search: String) {
    profiles(customerId: $customerId, limit: $limit, offset: $offset, search: $search) {
      ...ProfileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const PROFILE_QUERY = gql`
  query ($customerId: ID!, $id: ID!) {
    profile(customerId: $customerId, id: $id) {
      ...ProfileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const PROFILE_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID, $profile: ProfileInput!) {
    mutateProfile(customerId: $customerId, id: $id, profile: $profile) {
      ...ProfileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const PROFILE_DELETE_MUTATION = gql`
  mutation ($customerId: ID!, $id: ID!) {
    deleteProfile(customerId: $customerId, id: $id) {
      id
    }
  }
`;

export const NUMBER_GROUPS_QUERY = gql`
  query ($customerId: ID!, $search: String) {
    numberGroups(customerId: $customerId, search: $search) {
      id
      name
    }
  }
`;

export const MAIN_GROUPS_QUERY = gql`
  query ($customerId: ID!, $search: String) {
    mainGroups(customerId: $customerId, search: $search) {
      id
      name
    }
  }
`;
