import { useQuery } from "@apollo/client";

import { useFormikContext } from "formik";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Select from "react-select";

import { FormValues } from ".";
import ErrorMessage from "../../../../containers/Forms/ErrorMessage";
import { useAppSelector } from "../../../../hooks";
import { sessionSelector } from "../../../../reducers";
import { MainGroupsDataType } from "../../main_groups/types";
import { MAIN_GROUP_LIST } from "../queries";

export default function MainGroup() {
  const session = useAppSelector(sessionSelector);
  const context = useFormikContext<FormValues>();
  const { data: mainGroupsData } = useQuery<MainGroupsDataType>(MAIN_GROUP_LIST, {
    variables: { customerId: session.customer!.id },
  });
  const mainGroups = (mainGroupsData?.mainGroups || []).map((mg) => ({ label: mg.name, value: mg.id }));

  function handleChange(value: any) {
    context.setFieldValue("mainGroupId", value?.value || "");
  }

  return (
    <FormGroup>
      <FormLabel htmlFor="mainGroupId">Standort</FormLabel>
      <Select
        options={mainGroups}
        id="mainGroupId"
        placeholder="Standort wählen…"
        value={mainGroups.find((mg) => mg.value === context.values.mainGroupId)}
        onChange={handleChange}
      />
      <ErrorMessage name="numberGroupId" />
    </FormGroup>
  );
}
