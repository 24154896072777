import Button from "react-bootstrap/Button";
import Icon from "react-icons-kit";
import { ic_done } from "react-icons-kit/md";

import { TTButtonProps } from "./types";

export default function DoneButton(props: TTButtonProps) {
  return (
    <Button variant="secondary" {...props}>
      <Icon icon={props.icon || ic_done} /> {props.children || "fertig"}
    </Button>
  );
}
