import React from "react";

import Container from "react-bootstrap/Container";

import { useAppSelector } from "../../hooks";
import AdminAlertsSubnav from "../admin/alerts/Subnav";
import AdminMainGroupsSubnav from "../admin/main_groups/Subnav";
import AdminNumberGroupsSubnav from "../admin/number_groups/Subnav";
import AdminProfilesSubnav from "../admin/profiles/Subnav";
import AdminUsersSubnav from "../admin/users/Subnav";
import FlashList from "../Flash/FlashList";
import Header from "./Header";
import MainSubnav from "./MainSubnav";
import SubSubNav from "./SubSubNav";

type LayoutProps = { children: React.ReactChild | React.ReactChild[] };

const SUBNAVS: { [key: string]: React.ComponentType<any> } = {
  "admin/users": AdminUsersSubnav,
  "admin/maingroups": AdminMainGroupsSubnav,
  "admin/numbergroups": AdminNumberGroupsSubnav,
  "admin/profiles": AdminProfilesSubnav,
  "admin/alerts": AdminAlertsSubnav,
};

export default function Layout({ children }: LayoutProps) {
  const { subSubnav, props } = useAppSelector((state) => state.layout);
  const SubSubnavComponent = (subSubnav ? SUBNAVS[subSubnav] : null) || null;

  return (
    <>
      <Header />

      <Container as="main" role="main" fluid>
        <MainSubnav />
        {SubSubnavComponent && (
          <SubSubNav>
            <SubSubnavComponent {...props} />
          </SubSubNav>
        )}

        <FlashList />

        {children}
      </Container>
    </>
  );
}
