import "./style.scss";

import React from "react";

function NoDataRow({ data, cols }: { data: any; cols: number }) {
  if (data && (!data.hasOwnProperty("length") || data.length > 0)) return null;

  return (
    <tr className="TR-no-data-row">
      <td colSpan={cols}>keine Daten gefunden</td>
    </tr>
  );
}

export default React.memo(NoDataRow);
