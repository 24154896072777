import { useMutation, useQuery } from "@apollo/client";

import { Form, Formik, FormikHelpers } from "formik";
import { Link, useNavigate } from "react-router-dom";

import CancelButton from "../../containers/buttons/CancelButton";
import SaveButton from "../../containers/buttons/SaveButton";
import FormActionsContainer from "../../containers/FormActionsContainer";
import Loading from "../../containers/Loading";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { addErrorFlash, addSuccessFlash } from "../Flash/flashReducer";
import MainGroups from "./MainGroups";
import NumberGroups from "./NumberGroups";
import { DashboardConfigData, DashboardConfigType, GET_CONFIG_QUERY, UPDATE_CONFIG_MUTATION } from "./queries";

export type FormValues = {
  numberGroups: string[];
  mainGroups: string[];
};

const initialValues = (config: DashboardConfigType): FormValues => ({
  numberGroups: config.numberGroups.map((ng) => ng.id),
  mainGroups: config.mainGroups.map((mg) => mg.id),
});

export default function DashboardConfigInterface() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { data } = useQuery<DashboardConfigData>(GET_CONFIG_QUERY, { variables: { customerId: session.customer!.id } });
  const navigate = useNavigate();
  const [updateConfigMutation] = useMutation(UPDATE_CONFIG_MUTATION, {
    update(cache, { data: { mutateDashboardConfig } }) {
      cache.writeQuery({
        query: GET_CONFIG_QUERY,
        variables: { customerId: session.customer!.id },
        data: { dashboardConfig: mutateDashboardConfig },
      });
    },
  });

  async function saveConfig(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) {
    setSubmitting(true);

    try {
      await updateConfigMutation({
        variables: {
          dashboardConfig: values,
          customerId: session.customer!.id,
        },
      });

      dispatch(addSuccessFlash("Die Konfiguration wurde erfolgreich gespeichert!"));
      navigate(`/${session.customer!.identifier}`);
    } catch (e) {
      setSubmitting(false);
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
    }
  }

  if (!data?.dashboardConfig) return <Loading stretch />;

  return (
    <Formik initialValues={initialValues(data.dashboardConfig)} onSubmit={saveConfig}>
      {() => (
        <Form>
          <h2>Dashboard-Konfiguration bearbeiten</h2>

          <NumberGroups />

          <MainGroups />

          <FormActionsContainer>
            <SaveButton type="submit" />
            <CancelButton as={Link as any} to={`/${session.customer!.identifier}`} />
          </FormActionsContainer>
        </Form>
      )}
    </Formik>
  );
}
