import "./style.scss";

import { Form, Formik, FormikHelpers } from "formik";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import FormActionsContainer from "../../../../containers/FormActionsContainer";
import ErrorMessage from "../../../../containers/Forms/ErrorMessage";
import Input from "../../../../containers/Forms/Input";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { adminProfilesPath } from "../../../../paths";
import { sessionSelector } from "../../../../reducers";
import { addErrorFlash } from "../../../Flash/flashReducer";
import { ProfileType } from "../types";
import MainGroups from "./MainGroups";
import NumberGroups from "./NumberGroups";
import Users from "./Users";

type FormProps = {
  profile?: ProfileType;
  onSave: (values: FormValues) => void;
};

export type FormValues = {
  name: string;
  users: string[];
  numberGroups: string[];
  mainGroups: string[];
};

const initialValues = (profile: ProfileType | undefined): FormValues => ({
  name: profile?.name || "",
  users: profile?.users?.map((user) => user.id) || [],
  numberGroups: profile?.numberGroups?.map((ng) => ng.id) || [],
  mainGroups: profile?.mainGroups?.map((mg) => mg.id) || [],
});

const validationSchema = Yup.object({
  name: Yup.string().required("Bitte geben Sie einen Namen an!"),
});

export default function RelForm({ profile, onSave }: FormProps) {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);

  async function handleSubmit(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) {
    setSubmitting(true);

    try {
      await onSave(values);
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
      setSubmitting(false);
    }
  }

  return (
    <Formik initialValues={initialValues(profile)} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(bag) => (
        <Form className="TR-admin-profiles-form">
          <FormGroup>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input name="name" id="name" />
            <ErrorMessage name="name" />
          </FormGroup>

          <Users />
          <NumberGroups />
          <MainGroups />

          <FormActionsContainer>
            <Button type="submit">speichern</Button>
            <Button as={Link as any} variant="secondary" to={adminProfilesPath(session)}>
              abbrechen
            </Button>
          </FormActionsContainer>
        </Form>
      )}
    </Formik>
  );
}
