import { useQuery } from "@apollo/client";

import { useFormikContext } from "formik";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Select from "react-select";

import { FormValues } from ".";
import ErrorMessage from "../../../../containers/Forms/ErrorMessage";
import { useAppSelector } from "../../../../hooks";
import { sessionSelector } from "../../../../reducers";
import { NumberGroupsDataType } from "../../number_groups/types";
import { NUMBER_GROUP_LIST } from "../queries";

export default function NumberGroup() {
  const session = useAppSelector(sessionSelector);
  const context = useFormikContext<FormValues>();
  const { data: numberGroupsData } = useQuery<NumberGroupsDataType>(NUMBER_GROUP_LIST, {
    variables: { customerId: session.customer!.id },
  });
  const numberGroups = (numberGroupsData?.numberGroups || []).map((ng) => ({ label: ng.name, value: ng.id }));

  function handleChange(value: any) {
    context.setFieldValue("numberGroupId", value?.value || "");
  }

  return (
    <FormGroup>
      <FormLabel htmlFor="numberGroupId">Nummerngruppe</FormLabel>
      <Select
        options={numberGroups}
        id="numberGroupId"
        placeholder="Nummerngruppe wählen…"
        value={numberGroups.find((ng) => ng.value === context.values.numberGroupId)}
        onChange={handleChange}
      />
      <ErrorMessage name="numberGroupId" />
    </FormGroup>
  );
}
