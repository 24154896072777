import { gql } from "@apollo/client";

export const USER_SEARCH = gql`
  query ($customerId: ID!, $search: String, $limit: Int!, $offset: Int!) {
    users(customerId: $customerId, search: $search, limit: $limit, offset: $offset) {
      id
      email
      firstname
      lastname
    }
  }
`;

export const USERS = gql`
  query ($customerId: ID!, $ids: [ID]!, $search: String) {
    users(customerId: $customerId, ids: $ids, search: $search) {
      id
      email
      firstname
      lastname
    }
  }
`;
