import { useMutation, useQuery } from "@apollo/client";

import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import ActionColumn from "../../../containers/ActionColumn";
import DeleteButton from "../../../containers/buttons/DeleteButton";
import EditButton from "../../../containers/buttons/EditButton";
import Loading from "../../../containers/Loading";
import NoDataRow from "../../../containers/NoDataRow";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { editAdminProfilePath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { relativeTime } from "../../../utils/dateUtils";
import FetchMore from "../../FetchMore";
import { addErrorFlash, addSuccessFlash } from "../../Flash/flashReducer";
import { PROFILE_DELETE_MUTATION, PROFILE_LIST_QUERY } from "./queries";
import SearchForm from "./SearchForm";
import { ProfilesDataType, ProfileType } from "./types";

export default function List() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { searchTerm: search } = useAppSelector((state) => state.profiles);

  const { data, fetchMore, loading } = useQuery<ProfilesDataType>(PROFILE_LIST_QUERY, {
    variables: { limit: 25, offset: 0, customerId: session.customer!.id, search },
  });
  const [deleteProfileMutation] = useMutation(PROFILE_DELETE_MUTATION, {
    update(cache, { data: { deleteProfile } }) {
      const profiles = cache.readQuery<ProfilesDataType>({
        query: PROFILE_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
      })!.profiles;

      cache.writeQuery({
        query: PROFILE_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
        data: { profiles: profiles.filter((u) => u.id !== deleteProfile.id) },
      });
    },
  });

  async function deleteProfile(profile: ProfileType) {
    try {
      await deleteProfileMutation({ variables: { id: profile.id, customerId: session.customer!.id } });
      dispatch(addSuccessFlash("Das Profil wurde erfolgreich gelöscht!"));
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
    }
  }

  return (
    <>
      {!data?.profiles && <Loading fullscreen backdrop />}

      <SearchForm />

      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Nummerngruppen</th>
            <th>Standorte</th>
            <th>Benutzer</th>
            <th>erstellt</th>
            <th>bearbeitet</th>
            <th />
          </tr>
        </thead>

        <tbody>
          <NoDataRow data={data?.profiles} cols={7} />

          {data?.profiles.map((profile) => (
            <tr key={profile.id}>
              <td>{profile.name}</td>
              <td>{profile.numberGroups.map((ng) => ng.name).join(", ")}</td>
              <td>{profile.mainGroups.map((mg) => mg.name).join(", ")}</td>
              <td>{profile.users.map((user) => user.email).join(", ")}</td>
              <td>{relativeTime(profile.insertedAt)}</td>
              <td>{relativeTime(profile.updatedAt)}</td>

              <ActionColumn>
                <EditButton size="sm" as={Link as any} to={editAdminProfilePath(session, profile)} />
                <DeleteButton size="sm" onClick={() => deleteProfile(profile)} />
              </ActionColumn>
            </tr>
          ))}
        </tbody>
      </Table>

      <FetchMore fetchMore={fetchMore} data={data} loading={loading} dataKey="profiles" />
    </>
  );
}
