import { useState } from "react";

import { useMutation } from "@apollo/client";

import { Form, FormGroup, Modal } from "react-bootstrap";

import CancelButton from "../../containers/buttons/CancelButton";
import SaveButton from "../../containers/buttons/SaveButton";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { DISABLE_OPT_MUTATION, type DisableOtpDataType, SESSION_USER_FRAGMENT } from "../App/queries";
import { setUser } from "../App/sessionReducer";
import { addErrorFlash, addSuccessFlash } from "../Flash/flashReducer";

type PropsType = {
  show: boolean;
  onHide: () => void;
};

export default function DisableOtpModal({ show, onHide }: PropsType) {
  const { user } = useAppSelector(sessionSelector);
  const [otp, setOtp] = useState("");
  const dispatch = useAppDispatch();

  const [disableOtpMutation] = useMutation<DisableOtpDataType>(DISABLE_OPT_MUTATION, {
    update(cache, { data }) {
      if (!user || !data) return;

      dispatch(setUser({ ...user, otp: false }));

      cache.writeFragment({
        id: cache.identify(user),
        fragment: SESSION_USER_FRAGMENT,
        data: {
          ...user,
          otp: false,
        },
      });
    },
  });

  async function disableOtp() {
    try {
      await disableOtpMutation({ variables: { otp } });

      onHide();
      dispatch(addSuccessFlash("Die 2-Faktor-Authentifizierung wurde erfolgreich deaktiviert."));
    } catch (e) {
      dispatch(
        addErrorFlash("Fehler beim Deaktivieren der 2-Faktor-Authentifizierung. Bitte versuchen Sie es erneut."),
      );
      console.error(e);
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>2-Faktor-Autentifizierung deaktiveren</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Bitte bestätigen Sie diese Aktion mit einem 2-Faktor-Code.</p>

        <FormGroup>
          <Form.Label>2-Faktor-Code</Form.Label>
          <Form.Control type="text" value={otp} onChange={(e) => setOtp(e.target.value)} />
        </FormGroup>
      </Modal.Body>

      <Modal.Footer>
        <SaveButton disabled={otp.length !== 6} onClick={disableOtp}>
          deaktivieren
        </SaveButton>
        <CancelButton onClick={onHide}>abbrechen</CancelButton>
      </Modal.Footer>
    </Modal>
  );
}
