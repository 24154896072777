import { useMutation } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks";
import useTitle from "../../hooks/useTitle";
import { sessionSelector } from "../../reducers";
import { addErrorFlash, addSuccessFlash } from "../Flash/flashReducer";
import ChangePasswordForm, { ChangePasswordFormValues } from "./ChangePasswordForm";
import { UPDATE_PASSWORD_MUTATION } from "./queries";

export default function ChangePasswordInterface() {
  const session = useAppSelector(sessionSelector);
  const dispatch = useAppDispatch();
  const [updatePasswordMutation] = useMutation(UPDATE_PASSWORD_MUTATION);
  const navigate = useNavigate();

  useTitle("Passwort ändern");

  async function updatePassword(values: ChangePasswordFormValues) {
    try {
      await updatePasswordMutation({
        variables: {
          customerId: session.customer!.id,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      });

      dispatch(addSuccessFlash("Das Passwort wurde erfolgreich geändert."));
      navigate(`/${session.customer!.identifier}`);
      window.location.reload();
    } catch (_e) {
      dispatch(addErrorFlash("Es ist etwas schief gegangen!"));
    }
  }

  return <ChangePasswordForm onSubmit={updatePassword} />;
}
