import { useMutation, useQuery } from "@apollo/client";

import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import ActionColumn from "../../../containers/ActionColumn";
import DeleteButton from "../../../containers/buttons/DeleteButton";
import EditButton from "../../../containers/buttons/EditButton";
import Loading from "../../../containers/Loading";
import NoDataRow from "../../../containers/NoDataRow";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { editAdminMainGroupPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { relativeTime } from "../../../utils/dateUtils";
import FetchMore from "../../FetchMore";
import { addErrorFlash, addSuccessFlash } from "../../Flash/flashReducer";
import { MAIN_GROUP_DELETE_MUTATION, MAIN_GROUP_LIST_QUERY } from "./queries";
import SearchForm from "./SearchForm";
import { MainGroupsDataType, MainGroupType } from "./types";

export default function List() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { searchTerm: search } = useAppSelector((state) => state.mainGroups);

  const { data, fetchMore, loading } = useQuery<MainGroupsDataType>(MAIN_GROUP_LIST_QUERY, {
    variables: { limit: 25, offset: 0, customerId: session.customer!.id, search },
  });
  const [deleteMainGroupMutation] = useMutation(MAIN_GROUP_DELETE_MUTATION, {
    update(cache, { data: { deleteMainGroup } }) {
      const mainGroups = cache.readQuery<MainGroupsDataType>({
        query: MAIN_GROUP_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
      })!.mainGroups;

      cache.writeQuery({
        query: MAIN_GROUP_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
        data: { mainGroups: mainGroups.filter((u) => u.id !== deleteMainGroup.id) },
      });
    },
  });

  async function deleteMainGroup(user: MainGroupType) {
    try {
      await deleteMainGroupMutation({ variables: { id: user.id, customerId: session.customer!.id } });
      dispatch(addSuccessFlash("Der Standort wurde erfolgreich gelöscht!"));
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
    }
  }

  return (
    <>
      {!data?.mainGroups && <Loading fullscreen backdrop />}

      <SearchForm />

      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>erstellt</th>
            <th>bearbeitet</th>
            <th />
          </tr>
        </thead>

        <tbody>
          <NoDataRow data={data?.mainGroups} cols={4} />

          {data?.mainGroups.map((mainGroup) => (
            <tr key={mainGroup.id}>
              <td>{mainGroup.name}</td>
              <td>{relativeTime(mainGroup.insertedAt)}</td>
              <td>{relativeTime(mainGroup.updatedAt)}</td>

              <ActionColumn>
                <EditButton size="sm" as={Link as any} to={editAdminMainGroupPath(session, mainGroup)} />
                <DeleteButton size="sm" onClick={() => deleteMainGroup(mainGroup)} />
              </ActionColumn>
            </tr>
          ))}
        </tbody>
      </Table>

      <FetchMore fetchMore={fetchMore} data={data} loading={loading} dataKey="mainGroups" />
    </>
  );
}
