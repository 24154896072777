import React from "react";

import { useMutation, useQuery } from "@apollo/client";

import { useNavigate, useParams } from "react-router-dom";

import Loading from "../../../containers/Loading";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import { adminMainGroupsPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addSuccessFlash } from "../../Flash/flashReducer";
import { MAIN_GROUP_MUTATION, MAIN_GROUP_QUERY } from "./queries";
import RelForm, { FormValues } from "./RelForm";
import { MainGroupDataType } from "./types";

export default function Edit() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { id } = useParams<{ id: string }>();
  const { data } = useQuery<MainGroupDataType>(MAIN_GROUP_QUERY, {
    variables: { customerId: session.customer!.id, id },
  });
  const [mainGroupMutationMutation] = useMutation(MAIN_GROUP_MUTATION);
  const navigate = useNavigate();

  useTitle(`Standort ${data?.mainGroup?.name || ""} bearbeiten`);

  async function onSave(values: FormValues) {
    await mainGroupMutationMutation({
      variables: {
        customerId: session.customer!.id,
        id: data!.mainGroup.id,
        mainGroup: values,
      },
    });

    dispatch(addSuccessFlash("Der Standort wurde erfolgreich editiert!"));
    navigate(adminMainGroupsPath(session));
  }

  if (!data?.mainGroup) return <Loading stretch />;

  return (
    <React.Fragment>
      <h2>Standort „{data.mainGroup.name}” bearbeiten</h2>

      <RelForm mainGroup={data.mainGroup} onSave={onSave} />
    </React.Fragment>
  );
}
