import React from "react";

import FetchMoreButton from "../containers/buttons/FetchMoreButton";

interface FetchMorePropsInterface {
  data: any;
  loading: boolean;
  fetchMore: Function;
  dataKey: string;
}

type FetchMoreCallbackType = (fetchMore: Function, data: any, key: string) => any;

const fetchMoreCallback: FetchMoreCallbackType = (fetchMore, data, key) =>
  fetchMore({
    variables: { offset: data[key].length },
    updateQuery: (prev: any, { fetchMoreResult }: any) => {
      if (!fetchMoreResult) return prev;

      return { ...prev, [key]: [...prev[key], ...fetchMoreResult[key]] };
    },
  });

function FetchMore({ data, loading, fetchMore, dataKey }: FetchMorePropsInterface) {
  return (
    <FetchMoreButton
      data={data}
      loading={loading}
      fetchMore={fetchMore}
      onFetchMore={(fetchMore, data) => fetchMoreCallback(fetchMore, data, dataKey)}
    />
  );
}

export default React.memo(FetchMore);
