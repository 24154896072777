import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import _ from "lodash";
import { ActionMeta, OnChangeValue, Options } from "react-select";
import AsyncSelect from "react-select/async";

import apollo from "../../apollo";
import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";
import { UsersDataType } from "../admin/users/types";
import { SessionState } from "../App/sessionReducer";
import { USER_SEARCH, USERS } from "./query";

export type OptionType = { label: string; value: string; [key: string]: any };
export type TROptionValueType<T extends boolean = false> = OnChangeValue<OptionType, T>;
export type TRActionType = ActionMeta<OptionType>;

type CallbackType = (options: Options<OptionType>) => void;

const loadOptions = _.debounce((inputValue: string, callback: CallbackType, session: SessionState) => {
  apollo
    .query<UsersDataType>({
      query: USER_SEARCH,
      variables: { search: inputValue, limit: 25, offset: 0, customerId: session.customer!.id },
    })
    .then(({ data }) => {
      if (data?.users) {
        const options = data.users.map((user) => ({
          label: `${user.lastname}, ${user.firstname} (${user.email})`,
          value: user.id,
        }));

        callback(options);
      }
    });
}, 250);

export default function UserSelector(props: any) {
  const session = useAppSelector(sessionSelector);
  const { data } = useQuery<UsersDataType>(USERS, {
    variables: { ids: props.selected, customerId: session.customer!.id },
    skip: !props.selected,
  });

  const values = (data?.users || []).map((user) => ({
    label: `${user.lastname}, ${user.firstname} (${user.email})`,
    value: user.id,
  }));

  let value = undefined;
  if (props.selected && (!props.isMulti || props.selected.length > 0)) {
    value = props.isMulti ? values : values[0];
  }

  useEffect(() => {
    return () => {
      loadOptions.cancel();
    };
  }, []);

  return (
    <AsyncSelect
      placeholder="Benutzer auswählen…"
      {...props}
      cacheOptions
      defaultOptions
      loadOptions={(inputValue, callback) => loadOptions(inputValue, callback, session)}
      value={value}
    />
  );
}
