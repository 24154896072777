import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { endOfDay, endOfMonth, formatISO, startOfDay, startOfMonth, subDays } from "date-fns";

import { MainGroupType } from "../admin/main_groups/types";
import { NumberGroupType } from "../admin/number_groups/types";

export type DateTabType = "today" | "yesterday" | "7days" | "30days" | "month" | "range";
type LayoutState = {
  start: string;
  stop: string;
  selectedTab: DateTabType;
  numberGroup: NumberGroupType | undefined;
  mainGroup: MainGroupType | undefined;
  selectedNumberGroups: NumberGroupType[];
  selectedMainGroups: MainGroupType[];
  useDashboard: boolean;
};

const initialState: LayoutState = {
  start: formatISO(startOfDay(new Date())),
  stop: formatISO(endOfDay(new Date())),
  numberGroup: undefined,
  mainGroup: undefined,
  selectedTab: "today",
  selectedMainGroups: [],
  selectedNumberGroups: [],
  useDashboard: true,
};

const reachabilityReducer = createSlice({
  name: "reachability",
  initialState,
  reducers: {
    setSelectedTab(state, action: PayloadAction<DateTabType>) {
      state.selectedTab = action.payload;
      let start;

      switch (action.payload) {
        case "today":
          start = startOfDay(new Date());
          state.start = formatISO(start);
          state.stop = formatISO(endOfDay(start));
          break;
        case "yesterday":
          start = startOfDay(subDays(new Date(), 1));
          state.start = formatISO(start);
          state.stop = formatISO(endOfDay(start));
          break;
        case "7days":
          state.start = formatISO(startOfDay(subDays(new Date(), 7)));
          state.stop = formatISO(endOfDay(endOfDay(new Date())));
          break;
        case "30days":
          state.start = formatISO(startOfDay(subDays(new Date(), 30)));
          state.stop = formatISO(endOfDay(endOfDay(new Date())));
          break;
        case "month":
          state.start = formatISO(startOfMonth(new Date()));
          state.stop = formatISO(endOfMonth(new Date()));
          break;
        case "range":
          break;
      }
    },
    setStart(state, action: PayloadAction<string>) {
      state.start = action.payload;
    },
    setStop(state, action: PayloadAction<string>) {
      state.stop = action.payload;
    },
    setGroups(state, action: PayloadAction<{ numberGroup?: NumberGroupType; mainGroup?: MainGroupType }>) {
      state.numberGroup = action.payload.numberGroup;
      state.mainGroup = action.payload.mainGroup;
    },
    setSelectedNumberGroups(state, action: PayloadAction<NumberGroupType[]>) {
      state.selectedNumberGroups = action.payload;

      if (state.numberGroup && !state.selectedNumberGroups.find((ng) => ng.id === state.numberGroup!.id)) {
        state.numberGroup = undefined;
      }
    },
    setSelectedMainGroups(state, action: PayloadAction<MainGroupType[]>) {
      state.selectedMainGroups = action.payload;

      if (state.mainGroup && !state.selectedMainGroups.find((mg) => mg.id === state.mainGroup!.id)) {
        state.mainGroup = undefined;
      }
    },
    toggleUseDashboard(state) {
      state.useDashboard = !state.useDashboard;
    },
  },
});

export const {
  setSelectedTab,
  setStart,
  setStop,
  setGroups,
  setSelectedMainGroups,
  setSelectedNumberGroups,
  toggleUseDashboard,
} = reachabilityReducer.actions;
export default reachabilityReducer.reducer;
