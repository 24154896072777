import { Form, Formik } from "formik";
import Frm from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import CancelButton from "../../containers/buttons/CancelButton";
import SaveButton from "../../containers/buttons/SaveButton";
import FormActionsContainer from "../../containers/FormActionsContainer";
import ErrorMessage from "../../containers/Forms/ErrorMessage";
import Input from "../../containers/Forms/Input";
import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../reducers";

export type ChangePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type ChangePasswordFormProps = {
  onSubmit: (values: ChangePasswordFormValues) => void;
};

const initialValues = { oldPassword: "", newPassword: "", confirmPassword: "" };

const validationSchema = Yup.object({
  oldPassword: Yup.string().required("Bitte geben Sie Ihr altes Passwort an!"),
  newPassword: Yup.string()
    .required("Bitte geben Sie ein neues Passwort an!")
    .min(12, "Bitte geben Sie ein Passwort mit einer Mindest-Länge von 12 Zeichen an!"),
  confirmPassword: Yup.string()
    .required("Bitte bestätigen Sie das neue Passwort!")
    .oneOf([Yup.ref("newPassword")], "Das neue Passwort und die Passwort-Bestätigung müssen übereinstimmen!"),
});

export default function ChangePasswordForm({ onSubmit }: ChangePasswordFormProps) {
  const session = useAppSelector(sessionSelector);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(bag) => (
        <Form>
          <h2>Password ändern</h2>

          <Frm.Group>
            <Frm.Label htmlFor="oldPassword">altes Passwort</Frm.Label>
            <Input name="oldPassword" id="oldPassword" type="password" />
            <ErrorMessage name="oldPassword" />
          </Frm.Group>

          <Frm.Group>
            <Frm.Label htmlFor="newPassword">neues Passwort</Frm.Label>
            <Input name="newPassword" id="newPassword" type="password" />
            <ErrorMessage name="newPassword" />
          </Frm.Group>

          <Frm.Group>
            <Frm.Label htmlFor="confirmPassword">Passwort-Bestätigung</Frm.Label>
            <Input name="confirmPassword" id="confirmPassword" type="password" />
            <ErrorMessage name="confirmPassword" />
          </Frm.Group>

          <FormActionsContainer>
            <SaveButton type="submit">Passwort ändern</SaveButton>
            <CancelButton as={Link as any} to={`/${session.customer!.identifier}`} />
          </FormActionsContainer>
        </Form>
      )}
    </Formik>
  );
}
