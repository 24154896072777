import React from "react";

import { useMutation, useQuery } from "@apollo/client";

import { useNavigate, useParams } from "react-router-dom";

import Loading from "../../../containers/Loading";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import { adminNumberGroupsPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addNoticeFlash, addSuccessFlash } from "../../Flash/flashReducer";
import { NUMBER_GROUP_MUTATION, NUMBER_GROUP_QUERY } from "./queries";
import RelForm, { FormValues } from "./RelForm";
import { NumberGroupDataType } from "./types";

export default function Edit() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { id } = useParams<{ id: string }>();
  const { data } = useQuery<NumberGroupDataType>(NUMBER_GROUP_QUERY, {
    variables: { id, customerId: session.customer!.id },
  });
  const [numberGroupMutationMutation] = useMutation(NUMBER_GROUP_MUTATION);
  const navigate = useNavigate();

  useTitle(`Nummerngruppe ${data?.numberGroup?.name || ""} bearbeiten`);

  async function onSave(values: FormValues) {
    const numbers = values.numbers.map((number) => ({
      id: number.id,
      number: number.number,
      mainGroupId: number.mainGroupId,
    }));

    const { data: rspData } = await numberGroupMutationMutation({
      variables: {
        customerId: session.customer!.id,
        id: data!.numberGroup.id,
        numberGroup: { ...values, numbers },
      },
    });

    dispatch(addSuccessFlash("Die Nummerngruppe wurde erfolgreich editiert!"));

    if (!rspData.mutateNumberGroup.preaggregated) {
      dispatch(
        addNoticeFlash(
          "Die Gruppenkonstellation wurde verändert, deshalb muss die Erreichbarkeit für diese Gruppe neu berechnet werden. Dies geschieht im Hintergrund und kann einige Zeit dauern. Während der Verarbeitungsphase wird die alte Erreichbarkeit angezeigt.",
          0,
        ),
      );
    }

    navigate(adminNumberGroupsPath(session));
  }

  if (!data?.numberGroup) return <Loading stretch />;

  return (
    <React.Fragment>
      <h2>Nummerngruppe „{data.numberGroup.name}” bearbeiten</h2>

      <RelForm numberGroup={data.numberGroup} onSave={onSave} />
    </React.Fragment>
  );
}
