import "./style.scss";

import { useAppSelector } from "../../hooks";
import Flash from "./Flash";

export default function FlashList() {
  const messages = useAppSelector((state) => state.flash);

  if (!messages.length) return null;

  return (
    <div className="TR-flash-message-list">
      {messages.map((msg) => (
        <Flash key={msg.id} flash={msg} />
      ))}
    </div>
  );
}
