import { useState } from "react";

import { Form, Formik, FormikHelpers } from "formik";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Input from "../../containers/Forms/Input";
import Spinner from "../../containers/Spinner";
import { useAppDispatch } from "../../hooks";
import useTitle from "../../hooks/useTitle";
import { loginUser } from "../App/sessionReducer";
import FlashList from "../Flash/FlashList";
import { addNoticeFlash } from "../Flash/flashReducer";

type ValuesInterface = { email: string; password: string; otp: string };

const validationSchema = yup.object({
  email: yup.string().required("Bitte geben Sie die Login-Emailadresse an!"),
  password: yup.string().required("Bitte geben Sie das Passwort an!"),
  otp: yup
    .string()
    .min(6, "Bitte geben Sie den 6-stelligen Code ein!")
    .max(6, "Bitte geben Sie den 6-stelligen Code ein!"),
});

const INITIAL_VALUES = { email: "", password: "", otp: "" };

export default function LoginForm() {
  const [otpNeeded, setOtpNeeded] = useState(false); // [1

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useTitle("Login");

  async function doLoginUser(values: ValuesInterface, { setSubmitting }: FormikHelpers<ValuesInterface>) {
    try {
      setSubmitting(true);
      await dispatch(loginUser(values.email, values.password, values.otp));
      navigate("/");
    } catch (error: any) {
      console.log(error, error.message);
      setSubmitting(false);

      if (error.message === "otp_required") {
        dispatch(addNoticeFlash("Bitte geben Sie den 6-stelligen Code aus ihrer Authenticator-App ein!"));
        setOtpNeeded(true);
      }
    }
  }

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={doLoginUser} validationSchema={validationSchema}>
      {({ isSubmitting }) => (
        <div className="TR-login-screen">
          <Form>
            <h1>Login Termitel-Erreichbarkeit</h1>

            <FlashList />

            <label className="visually-hidden" htmlFor="email">
              Email-Adresse
            </label>
            <Input name="email" id="email" type="email" />

            <label className="visually-hidden" htmlFor="password">
              Passwort
            </label>
            <Input name="password" id="password" type="password" />

            {otpNeeded && (
              <>
                <label className="visually-hidden" htmlFor="otp">
                  6-stelliger Code
                </label>
                <Input name="otp" id="otp" type="text" className="otp" />
              </>
            )}

            <Button color="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting && <Spinner />} einloggen
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );
}
