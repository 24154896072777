import { useMutation, useQuery } from "@apollo/client";

import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import ActionColumn from "../../../containers/ActionColumn";
import DeleteButton from "../../../containers/buttons/DeleteButton";
import EditButton from "../../../containers/buttons/EditButton";
import Loading from "../../../containers/Loading";
import NoDataRow from "../../../containers/NoDataRow";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { editAdminAlertPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import FetchMore from "../../FetchMore";
import { addErrorFlash, addSuccessFlash } from "../../Flash/flashReducer";
import { ALERT_DELETE_MUTATION, ALERT_LIST_QUERY } from "./queries";
import SearchForm from "./SearchForm";
import { AlertsDataType, AlertType } from "./types";

export default function List() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const { searchTerm: search } = useAppSelector((state) => state.alerts);

  const { data, fetchMore, loading } = useQuery<AlertsDataType>(ALERT_LIST_QUERY, {
    variables: { limit: 25, offset: 0, customerId: session.customer!.id, search },
  });
  const [deleteAlertMutation] = useMutation(ALERT_DELETE_MUTATION, {
    update(cache, { data: { deleteAlert } }) {
      const alerts = cache.readQuery<AlertsDataType>({
        query: ALERT_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
      })!.alerts;

      cache.writeQuery({
        query: ALERT_LIST_QUERY,
        variables: { customerId: session.customer!.id, search },
        data: { alerts: alerts.filter((u) => u.id !== deleteAlert.id) },
      });
    },
  });

  async function deleteAlert(alert: AlertType) {
    try {
      await deleteAlertMutation({ variables: { id: alert.id, customerId: session.customer!.id } });
      dispatch(addSuccessFlash("Der Alarm wurde erfolgreich gelöscht!"));
    } catch (_e) {
      dispatch(addErrorFlash("Es ist ein Fehler aufgetreten!"));
    }
  }

  return (
    <>
      {!data?.alerts && <Loading fullscreen backdrop />}

      <SearchForm />

      <Table>
        <thead>
          <tr>
            <th>Standort</th>
            <th>Gruppe</th>
            <th>Erreichbarkeit</th>
            <th>in Gruppe</th>
            <th>Benachrichtigungen</th>
            <th />
          </tr>
        </thead>

        <tbody>
          <NoDataRow data={data?.alerts} cols={6} />

          {data?.alerts.map((alert) => (
            <tr key={alert.id}>
              <td>{alert.mainGroup.name}</td>
              <td>{alert.numberGroup.name}</td>
              <td>{alert.reachability} %</td>
              <td>{alert.inGroup ? "ja" : "nein"}</td>
              <td>{alert.emails.join(", ")}</td>

              <ActionColumn>
                <EditButton size="sm" as={Link as any} to={editAdminAlertPath(session, alert)} />
                <DeleteButton size="sm" onClick={() => deleteAlert(alert)} />
              </ActionColumn>
            </tr>
          ))}
        </tbody>
      </Table>

      <FetchMore fetchMore={fetchMore} data={data} loading={loading} dataKey="alerts" />
    </>
  );
}
