import React from "react";

import { useMutation } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import { adminProfilesPath } from "../../../paths";
import { sessionSelector } from "../../../reducers";
import { addSuccessFlash } from "../../Flash/flashReducer";
import { PROFILE_MUTATION } from "./queries";
import RelForm, { FormValues } from "./RelForm";

export default function Edit() {
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const [userMutation] = useMutation(PROFILE_MUTATION);
  const navigate = useNavigate();

  useTitle("neues Profil");

  async function onSave(values: FormValues) {
    await userMutation({ variables: { profile: values, customerId: session.customer!.id } });

    dispatch(addSuccessFlash("Das Profil wurde erfolgreich erstellt!"));
    navigate(adminProfilesPath(session));
  }

  return (
    <React.Fragment>
      <h2>neues Profil</h2>

      <RelForm onSave={onSave} />
    </React.Fragment>
  );
}
