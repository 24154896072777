import { gql } from "@apollo/client";

import type { UserType } from "../admin/users/types";
import type { CustomerType } from "./sessionReducer";

export type CustomerDataType = {
  customer: CustomerType;
};

export type EnableOtpDataType = {
  enableOtp: string;
};

export type DisableOtpDataType = {
  disableOtp: UserType;
};

export const CUSTOMER_GET_QUERY = gql`
  query ($id: ID!) {
    customer(id: $id) {
      id
      name
      identifier
    }
  }
`;

export const SESSION_USER_FRAGMENT = gql`
  fragment SessionUserFragment on User {
    id
    email
    active
    otp
    role
    firstname
    lastname
    customerId
  }
`;

export const LOGIN_MUTATION = gql`
  mutation ($email: String!, $password: String!, $otp: String) {
    login(email: $email, password: $password, otp: $otp) {
      user {
        ...SessionUserFragment
      }

      token
    }
  }
  ${SESSION_USER_FRAGMENT}
`;

export const REFRESH_MUTATION = gql`
  mutation ($token: String!) {
    refresh(token: $token) {
      user {
        ...SessionUserFragment
      }

      token
    }
  }
  ${SESSION_USER_FRAGMENT}
`;

export const ENABLE_OTP_MUTATION = gql`
  mutation {
    enableOtp
  }
`;

export const DISABLE_OPT_MUTATION = gql`
  mutation ($otp: String!) {
    disableOtp(otp: $otp) {
      ...SessionUserFragment
    }
  }
  ${SESSION_USER_FRAGMENT}
`;
