import * as Sentry from "@sentry/react";

import store from "./store";

export function setContext() {
  const state = store.getState();

  Sentry.setUser({
    id: state.session?.user?.id,
    email: state.session?.user?.email,
  });
}
