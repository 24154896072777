import Icon from "react-icons-kit";
import { ic_delete } from "react-icons-kit/md";

import ConfirmButton from "./ConfirmButton";
import { TTButtonProps } from "./types";

export default function DeleteButton(props: TTButtonProps) {
  return (
    <ConfirmButton {...props}>
      <Icon icon={ic_delete} /> {props.children || "löschen"}
    </ConfirmButton>
  );
}
